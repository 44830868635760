import { useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useQuery } from "@apollo/client";
import UserDataService from "../services/user.service";
import {
  ReportsIcon,
  DashboardIcon,
  NavIcons,
  DocumentManagerIcon,
  PartnersIcon,
  ClaimsIcon,
  GearIcon,
  KnowledgeCenterIcon,
  InsightsIcon,
  ComplianceIcon,
} from "./icons";
import { useState } from "react";
import { useAuth } from "../auth.js";
import RouterHelper from "../utilities/router-helper";
import NavSelection from "./nav-selection";
import { getPermissions } from "../queries";
import { muiSelect, muiMenuItem } from "../styles/mui-overrides";
import { NotificationsContext } from "../utilities/notifications-context";
import { FormControl, Select, MenuItem } from "@mui/material";
import { Image } from "../image";
import useVisited from "./useVisited";
import AccessControlled from "../components/AccessControlled";

export const pathNameToIconMap: { [index: string]: NavIcons } = {
  "/claim": NavIcons.DashboardIcon,
  "/reports": NavIcons.ReportsIcon,
  "/admin/reports": NavIcons.ReportsIcon,
  "/report_details": NavIcons.ReportsIcon,
  "/knowledge-center": NavIcons.KnowledgeCenterIcon,
  "/settings": NavIcons.GearIcon,
  "/settings/user": NavIcons.GearIcon,
  "/admin/settings/user": NavIcons.GearIcon,
  "/settings/locations": NavIcons.GearIcon,
  "/admin/settings/locations": NavIcons.GearIcon,
  "/settings/billing": NavIcons.GearIcon,
  "/admin/settings/billing": NavIcons.GearIcon,
  "/settings/auto_protect": NavIcons.GearIcon,
  "/admin/settings/auto_protect": NavIcons.GearIcon,
  "/settings/notifications": NavIcons.GearIcon,
  "/settings/contact": NavIcons.GearIcon,
  "/settings/organization": NavIcons.GearIcon,
  "/settings/my-account": NavIcons.GearIcon,
  "/admin/relationships": NavIcons.PartnersIcon,
  "/admin/tools": NavIcons.ClaimsIcon,
  "/documents": NavIcons.DocumentManagerIcon,
  "/admin/documents": NavIcons.DocumentManagerIcon,
  "/insights": NavIcons.InsightsIcon,
  "/admin/insights": NavIcons.InsightsIcon,
  "/compliance": NavIcons.ComplianceIcon,
  "/plan-upgrades": NavIcons.PlanUpgradesIcon
  //TODO eventually associate all paths with a nav icon
};

function NavBar(props: { routerHelper: RouterHelper }) {
  const { routerHelper } = props;
  const [selectedIcon, setSelectedIcon] = useState<NavIcons | null>(null);
  const auth = useAuth();
  const history = useHistory();
  const location = useLocation();
  const {
    hasVisited: hasVisitedCompliance,
    handleOnClick: handleOnClickVisitedCompliance,
  } = useVisited("compliance");

  useEffect(() => {
    // Matches "/", "/admin/271", "/admin/271/claim/recgJFXfsdfsssfenOSqt" to Dashboard
    if (location.pathname === "/" || location.pathname.match(/^\/admin\/\d/)) {
      setSelectedIcon(NavIcons.DashboardIcon);
      return;
    }

    for (const pathName of Object.keys(pathNameToIconMap)) {
      if (location.pathname.startsWith(pathName)) {
        setSelectedIcon(pathNameToIconMap[pathName]);
        return;
      }
    }
  }, [location]);

  const permissions = useQuery(getPermissions, { skip: auth.user?.isAdmin });

  function iconClicked(event: any, iconName: NavIcons) {
    setSelectedIcon(iconName);
  }

  async function changeActivePermission(event: any) {
    const response = await UserDataService.switchPermissions({
      permissionId: event.target.value,
    });

    if (response.data) {
      const user = { ...auth.user };
      user.relationshipId = response.data.relationshipId;
      user.role = response.data.role;
      user.locationIds = response.data.locationIds;
      await auth.signin(user);
      routerHelper.setRelationshipId(response.data.relationshipId);
      history.go(0);
    }
  }

  if (!auth.user) return null;

  return (
    <NotificationsContext.Consumer>
      {(notificationsContext) => (
        <div className="nav-bar">
          <div className="">
            <Link to="/">
              <Image
                alt="SafeLease logo"
                className="nav-bar--logo"
                src="/images/logos/horizontal/safelease_logohorizontal_rgb_blueonnavyblue.svg"
              />
            </Link>

            {permissions?.data?.getPermissions.length > 1 && (
              <FormControl
                sx={{ marginTop: 1, minWidth: "80%", maxWidth: "100%" }}
              >
                <Select
                  value={
                    permissions.data.getPermissions.find(
                      (permission) =>
                        permission.relationshipId == auth.user.relationshipId
                    )?.id
                  }
                  displayEmpty
                  onChange={(e) => changeActivePermission(e)}
                  sx={{
                    ...muiSelect,
                    marginRight: "15px",
                    marginLeft: "15px",
                    padding: "0px",
                  }}
                >
                  {permissions.data.getPermissions.map((permission) => (
                    <MenuItem
                      sx={{ ...muiMenuItem, paddingLeft: "15px" }}
                      key={permission.id}
                      value={permission.id}
                    >
                      {permission.relationshipName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}

            <div className="nav-bar--icons">
              {routerHelper.getIsInsuranceUser() && (
                <NavSelection
                  title="Dashboard"
                  linkTo={() => routerHelper.dashboardRoute()}
                  onClick={(event) =>
                    iconClicked(event, NavIcons.DashboardIcon)
                  }
                  icon={NavIcons.DashboardIcon}
                  selectedIcon={selectedIcon}
                  hasNotification={notificationsContext.NotificationsHelper.hasClaimNoteNotification(
                    notificationsContext.allNotifications
                  )}
                >
                  <DashboardIcon />
                </NavSelection>
              )}

              {routerHelper.getIsInsightsUser() && (
                <NavSelection
                  title="Insights"
                  linkTo={() => routerHelper.insightsRoute(null)}
                  onClick={(event) => iconClicked(event, NavIcons.InsightsIcon)}
                  icon={NavIcons.InsightsIcon}
                  selectedIcon={selectedIcon}
                >
                  <InsightsIcon />
                </NavSelection>
              )}

              {routerHelper.getIsInsuranceUser() && (
                <>
                  <NavSelection
                    title="Reports"
                    linkTo={() => routerHelper.reportsRoute()}
                    onClick={(event) =>
                      iconClicked(event, NavIcons.ReportsIcon)
                    }
                    icon={NavIcons.ReportsIcon}
                    selectedIcon={selectedIcon}
                  >
                    <ReportsIcon />
                  </NavSelection>

                  <NavSelection
                    title="Knowledge Center"
                    linkTo="/knowledge-center"
                    onClick={(event) =>
                      iconClicked(event, NavIcons.KnowledgeCenterIcon)
                    }
                    icon={NavIcons.KnowledgeCenterIcon}
                    selectedIcon={selectedIcon}
                  >
                    <KnowledgeCenterIcon />
                  </NavSelection>

                  <NavSelection
                    title="Documents"
                    icon={NavIcons.DocumentManagerIcon}
                    linkTo={() => routerHelper.documentsRoute()}
                    hasNotification={
                      notificationsContext.NotificationsHelper.hasInvoiceNotification(
                        notificationsContext.allNotifications
                      ) > 0
                    }
                    onClick={(event) =>
                      iconClicked(event, NavIcons.DocumentManagerIcon)
                    }
                    selectedIcon={selectedIcon}
                  >
                    <DocumentManagerIcon />
                  </NavSelection>
                </>
              )}

              <NavSelection
                title="Settings"
                linkTo="/settings"
                onClick={(event) => iconClicked(event, NavIcons.GearIcon)}
                icon={NavIcons.GearIcon}
                selectedIcon={selectedIcon}
              >
                <GearIcon />
              </NavSelection>
              {auth.user?.isAdmin && (
                  <NavSelection
                    title="Relationships"
                    icon={NavIcons.PartnersIcon}
                    linkTo="/admin/relationships"
                    onClick={(event) =>
                      iconClicked(event, NavIcons.PartnersIcon)
                    }
                    selectedIcon={selectedIcon}
                  >
                    <PartnersIcon />
                  </NavSelection>
              )}
                <NavSelection
                  title="Compliance"
                  icon={NavIcons.ComplianceIcon}
                  linkTo={() => routerHelper.complianceRoute()}
                  onClick={(event) => {
                    iconClicked(event, NavIcons.ComplianceIcon);
                    handleOnClickVisitedCompliance();
                  }}
                  hasNotification={!hasVisitedCompliance}
                  selectedIcon={selectedIcon}
                  displayNewChip
                >
                  <ComplianceIcon />
                </NavSelection>
              {auth.user?.isAdmin && (
                  <NavSelection
                    title="Admin Tools"
                    icon={NavIcons.ClaimsIcon}
                    linkTo="/admin/tools"
                    onClick={(event) => iconClicked(event, NavIcons.ClaimsIcon)}
                    selectedIcon={selectedIcon}
                  >
                    <ClaimsIcon />
                  </NavSelection>
              )}
            </div>
          </div>
        </div>
      )}
    </NotificationsContext.Consumer>
  );
}

export default NavBar;
