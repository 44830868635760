import Insights from "./insights/Insights";
import { useState, useEffect, lazy, Suspense } from "react";
import { useOtpStore } from "./utilities/otp-state";
import * as Sentry from "@sentry/react";
require("polyfill-object.fromentries");
import { Integrations } from "@sentry/tracing";
import { LicenseInfo } from "@mui/x-license-pro";

import "./app.scss";
import "./styles/dashboard.scss";
import "./styles/onboarding.scss";
import "./styles/title-header.scss";
import "./styles/report-details.scss";
import "./styles/settings.scss";
import "./styles/nav-bar.scss";
import "./styles/signin.scss";
import "./styles/photo-picker.scss";
import "./styles/knowledge-center.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap-reboot.min.css";
import "./styles/bootstrap-overrides.scss";
import "@fontsource/roboto";
import RouterHelper from "./utilities/router-helper";
import MatchClaims from "./match-claims";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useHistory,
  useLocation,
} from "react-router-dom";

import {
  RequestEmailSignin,
  EmailSignin,
  ResetPasswordEmail,
  ResetPassword,
  Unsubscribe,
  CreateProfile,
} from "./users";
const DocumentPortal = lazy(async () =>
  import("./document-portal/document-portal"),
);
const KnowledgeCenter = lazy(async () => import("./knowledge-center"));
import {
  AdminTools,
  AdminReports,
  AdminBillingReport,
  AttachRateReport,
  OtpEnroll,
  Credentials,
  BillingCredentials,
  UpcomingEmails,
  RetryJob,
  ImpactReport,
  LocationsMap,
} from "./admin";
import { PageMessage } from "./common";
import { Dashboard } from "./dashboard/dashboard";
const JobHealth = lazy(async () => import("./job-health"));
const UserInfoTable = lazy(async () => import("./user-info-table"));
const RelationshipsTable = lazy(async () =>
  import("./relationships/relationships-table"),
);

import { Reports } from "./reports";
import { LegacyReports } from "./reports/LegacyReports";
import { ReportDetails } from "./report-details";
const NavBar = lazy(async () => import("./nav-bar/nav-bar"));
const NotificationsModal = lazy(async () => import("./notifications-modal"));
import { NotificationsProvider } from "./utilities/notifications-context";
const UserFeedback = lazy(async () => import("./components/user-feedback"));
import { Notes } from "./notes/notes";
const SecureRedirect = lazy(async () => import("./secure-redirect"));
import { Invoices } from "./invoices/invoices";
import { RecurringInvoiceLineItems } from "./invoices/recurringInvoiceLineItems";

import { useAuth, ProvideAuth } from "./auth.js";
import { ApolloProvider, useQuery } from "@apollo/client";
import apolloClient from "./utilities/apolloClient";
import { QueryParamProvider } from "use-query-params";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import "@fontsource/open-sans";
import { AuditRouteWrapper } from "./utilities/audit-wrapper";
import { mixpanelEventHandler } from "./utilities/reactMixpanelHandler";
import Loader from "./shared/Loader";
import { getRelationship, me } from "./queries";
import { SafeleaseRoute } from "./utilities/safelease-route";
import { SettingsRouter } from "./utilities/settings-router";
import EmailVerification from "./components/EmailVerification";
import Error from "./shared/Error";
import WelcomeToSafelease from "./components/WelcomeToSafelease";
import {
  JobsErrorCenter,
  GeneralUnitsHistory,
  AdminBilling,
} from "./admin-tools";
import SyncOnOpportunityLocationId from "./salesforce/SyncOnOpportunityLocationId";
import SyncOnOpportunityId from "./salesforce/SyncOnOpportunityId";
import { SignInPage } from "./pages/SignIn";
import { PrivatePolicyReport } from "./compliance/PrivatePolicyReport";
import { NavigationDrawer } from "./NavigationDrawer";
import { PlanUpgrades } from "./settings/PlanUpgrades/PlanUpgrades";
import { PlanUpgradeTool } from "./reports/TenantProtectionActivityReport/PlanUpgradeTool";
import { ComplianceCenter } from "./admin-tools/ComplianceCenter";
import { EngineeringErrorReport } from "./admin-tools/JobsErrorCenter/EngineeringErrorReport";
import { ReputationManagement } from "./reputation/ReputationManagement";
import { FacilityView } from "./reputation/pages/FacilityView";
import { ThemeProvider } from "@mui/material";
import { theme } from "@safelease/components";
import { QueryClientProvider } from "@tanstack/react-query";
import { reactQueryClient } from "./utilities/reactQueryClent";
import Hotjar from '@hotjar/browser';

const SentryRoute = Sentry.withSentryRouting(Route);
if (["app.safelease.com", "staging.safelease.com"].includes(window.location.hostname)) {
  Sentry.init({
    dsn: "https://8b901423896d4b6bb4bb6932a5ab7668@o1109446.ingest.sentry.io/6137813",
    integrations: [
      Sentry.reactRouterV5BrowserTracingIntegration({ history }),
    ],
    tracesSampleRate: 1.0,
    release: process.env.REACT_APP_BUILD,
    environment: window.location.hostname === "app.safelease.com" ? "production" : "staging",
  });
}

LicenseInfo.setLicenseKey(
  "ac9cf4c13c4831708ffd10b7b09fcf55Tz05NjkxNixFPTE3NTYzMDM0MDgwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixQVj1pbml0aWFsLEtWPTI=",
);


const siteId = 5042184;
const hotjarVersion = 6;

Hotjar.init(siteId, hotjarVersion);


function App() {
  const [routerHelper] = useState(new RouterHelper());

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ApolloProvider client={apolloClient}>
        <ProvideAuth routerHelper={routerHelper}>
          <Suspense fallback={<Loader />}>
            <Router>
              <QueryParamProvider ReactRouterRoute={Route}>
                <Main routerHelper={routerHelper} />
              </QueryParamProvider>
            </Router>
          </Suspense>
        </ProvideAuth>
      </ApolloProvider>
    </LocalizationProvider>
  );
}

function Main(props) {
  const { routerHelper } = props;
  const auth = useAuth();
  const history = useHistory();
  const location = useLocation();
  const { checkOtpVerified } = useOtpStore((state) => ({
    checkOtpVerified: state.checkOtpVerified,
  }));

  if(auth && auth.user) {
    Sentry.setUser({
      id: auth.user.id,
      name: auth.user.name,
    });
  }

  /* If auth.user is fetched or url path changes, update the relationshipId belonging to
     the global routerHelper accordingly. Only do this if the route includes a route associated with god mode */
  useEffect(() => {
    const godModeParams /*: null | {relationshipId?: string, locationId?: string}*/ =
      RouterHelper.matchGodModeParams(location.pathname);

    /* If the user is an admin and is visitiing a god mode route, use the relationshipId supplied by the route if there is one.
       If the user has no relationshipId in the route params, check if there is one in memory in RouterHelper. Else,
       use their default relationshipId returned by the API */
    const relationshipId =
      godModeParams?.relationshipId ||
      routerHelper.relationshipId ||
      auth.user?.relationshipId;

    const locationId = godModeParams?.locationId;

    routerHelper.setUser(auth.user);
    routerHelper.setRelationshipId(relationshipId);
    if (auth.user?.email)
      mixpanelEventHandler("Pageview - " + location.pathname);
  }, [JSON.stringify(auth.user), JSON.stringify(location.pathname)]);

  useEffect(() => {
    checkOtpVerified(localStorage);
    if (auth.user) user.refetch();
  }, [auth.user]);

  const isNavBarPath = (path /*: string*/) /*: boolean*/ => {
    if (
      path.includes("onboarding") ||
      path.includes("signup") ||
      path.includes("signin") ||
      path.includes("reset_password") ||
      path.includes("email_signin") ||
      path.includes("add_plan")
    ) {
      return false;
    }
    return true;
  };

  const [showNavBar, setShowNavBar] = useState(isNavBarPath(location.pathname));

  history.listen((location, action) => {
    setShowNavBar(auth.user && isNavBarPath(location.pathname));
  });

  const user = useQuery(me, { skip: !auth.user });

  const relationshipQuery = useQuery(getRelationship, {
    skip: !auth.user?.relationshipId,
    variables: { id: auth.user?.relationshipId },
  });
  if (auth.user?.relationshipId && !relationshipQuery.data?.getRelationship)
    return <Loader />;
  const relationship = relationshipQuery.data?.getRelationship;

  routerHelper.setIsAutoProtectUser(
    auth.user?.isAdmin ||
      (relationship?.isInsuranceSetupComplete &&
        !relationship?.isSafeleaseLiteEnabled),
  );
  routerHelper.setIsInsuranceUser(
    auth.user?.isAdmin || relationship?.isInsuranceEnabled,
  );
  routerHelper.setIsInsightsUser(
    ((auth.user?.isAdmin || relationship?.isInsightsEnabled) &&
      (relationship?.featureFlags?.includes("insights")) ||
      user?.data?.me.features.includes("insights"))
  ); // INSIGHTS TODO: remove the Insights feature flag condition (SA-1914)
  
  routerHelper.setFeatureFlags([
    ...(relationship?.featureFlags ?? []),
    ...(user?.data?.me?.features ?? []),
  ]);
  routerHelper.setIsLocationsExistUser(
    auth.user?.isAdmin || relationship?.hasLocations,
  );

  if (window.location.hostname.toLowerCase().includes("privatepolicy")) {
    if (process.env.NODE_ENV !== "production") {
      window.location.href = "https://tenant-staging.safelease.com";
    } else {
      window.location.href = "https://tenant.safelease.com";
    }
  }

  if (!auth.user || location.pathname.includes("email_signin")) {
    return (
      <Switch>
        <SentryRoute path="/invitation/:token" component={CreateProfile} />
        <SentryRoute path="/signin" component={SignInPage} />
        <SentryRoute path="/signup" component={CreateProfile} />
        <SentryRoute path="/" exact component={SignInPage} />
        <SentryRoute exact path="/reset_password" component={ResetPasswordEmail} />
        <SentryRoute exact path="/reset_password/:token" component={ResetPassword} />
        <SentryRoute exact path="/email_signin" component={RequestEmailSignin} />
        <SentryRoute exact path="/email_signin/:token" component={EmailSignin} />
        <SentryRoute path="/messages/:type" component={PageMessage} />
        <SentryRoute path="/unsubscribe/:token" component={Unsubscribe} />
        <Redirect from="*" to="/" />
      </Switch>
    );
  }

  if (user.loading) return <Loader />;
  if (user.error) return <Error />;
  if (!user.data?.me?.emailVerifiedAt)
    return <EmailVerification refetch={() => user.refetch()} />;

  if (
    !routerHelper.getIsLocationsExistUser() &&
    routerHelper.getFeatureFlags()?.includes("insights")
  )
    return <WelcomeToSafelease routerHelper={routerHelper} />; // INSIGHTS TODO: remove the Insights feature flag condition (SA-1914)

  return (
    <NotificationsProvider>
      <div className="page-wrapper">
        {auth.user && <NotificationsModal routerHelper={routerHelper} />}
        {auth.user && <UserFeedback />}
        <NavigationDrawer routerHelper={routerHelper} />
        {/* {showNavBar && <NavBar routerHelper={routerHelper} />} */}
        <div
          className={`right-of-nav-bar ${
            showNavBar ? "right-of-nav-bar__with-nav" : ""
          }`}
        >
          <div className="content-container">
            <div className="content">
              <Switch>
                <SafeleaseRoute exact path="/reset_password">
                  <ResetPasswordEmail />
                </SafeleaseRoute>
                <SafeleaseRoute exact path="/reset_password/:token">
                  <ResetPassword />
                </SafeleaseRoute>
                <SafeleaseRoute exact path="/email_signin">
                  <RequestEmailSignin />
                </SafeleaseRoute>
                <SafeleaseRoute exact path="/email_signin/:token">
                  <EmailSignin />
                </SafeleaseRoute>
                <SafeleaseRoute path="/messages/:type">
                  <PageMessage />
                </SafeleaseRoute>
                <SafeleaseRoute path="/signup">
                  <CreateProfile />
                </SafeleaseRoute>
                <SafeleaseRoute path="/signin">
                  <SignInPage />
                </SafeleaseRoute>
                <SafeleaseRoute path="/unsubscribe/:token">
                  <Unsubscribe />
                </SafeleaseRoute>
                <SafeleaseRoute path="/reset_password_emailed">
                  An email has been sent to your account with a link to reset
                  your password.
                </SafeleaseRoute>
                <SafeleaseRoute path="/invitation/:token">
                  <CreateProfile />
                </SafeleaseRoute>

                {/*<SafeleaseRoute path="/vendor-sourcing">*/}
                {/*  <VendorSourcingPage />*/}
                {/*</SafeleaseRoute>*/}

                  <SafeleaseRoute path="/settings">
                    <QueryClientProvider client={reactQueryClient}>
                      <SettingsRouter routerHelper={routerHelper} />
                    </QueryClientProvider>
                  </SafeleaseRoute>
                  <SafeleaseRoute path="/admin/settings" redirect>
                    <SettingsRouter routerHelper={routerHelper} isAdminRoute />
                  </SafeleaseRoute>

         
                <SafeleaseRoute
                  exact
                  path="/insights"
                  redirect={!routerHelper.getIsInsightsUser()}
                >
                  <Insights routerHelper={routerHelper} />
                </SafeleaseRoute>
                <SafeleaseRoute
                  exact
                  path="/admin/insights/:relationshipId"
                  redirect
                >
                  <Insights routerHelper={routerHelper} />
                </SafeleaseRoute>

                <SafeleaseRoute
                  exact
                  path="/reports"
                  redirect={!routerHelper.getIsInsuranceUser()}
                >
                  <LegacyReports routerHelper={routerHelper} />
                </SafeleaseRoute>
                <SafeleaseRoute
                  path="/admin/reports/:relationshipId"
                  redirect={!routerHelper.getIsInsuranceUser()}
                >
                  <Reports routerHelper={routerHelper} />
                </SafeleaseRoute>
                <SafeleaseRoute
                  path="/report_details/:relationshipId/:locationId"
                  redirect={!routerHelper.getIsInsuranceUser()}
                >
                  <ReportDetails routerHelper={routerHelper} />
                </SafeleaseRoute>

                <SafeleaseRoute path="/plan-upgrades">
                  <PlanUpgradeTool routerHelper={routerHelper} />
                </SafeleaseRoute>
                <SafeleaseRoute path="/compliance">
                  <PrivatePolicyReport routerHelper={routerHelper} />
                </SafeleaseRoute>

                <SafeleaseRoute
                  path="/knowledge-center"
                  redirect={!routerHelper.getIsInsuranceUser()}
                >
                  <KnowledgeCenter />
                </SafeleaseRoute>

                <SafeleaseRoute
                  path="/documents"
                  redirect={!routerHelper.getIsInsuranceUser()}
                >
                  <DocumentPortal routerHelper={routerHelper} />
                </SafeleaseRoute>
                <SafeleaseRoute
                  path="/admin/documents/:relationshipId"
                  redirect
                >
                  <DocumentPortal routerHelper={routerHelper} />
                </SafeleaseRoute>
                <SafeleaseRoute
                  path="/secure-redirect"
                  redirect={!routerHelper.getIsInsuranceUser()}
                >
                  <SecureRedirect />
                </SafeleaseRoute>

                <SafeleaseRoute path="/admin/relationships" redirect>
                  <RelationshipsTable routerHelper={routerHelper} />
                </SafeleaseRoute>

                <SafeleaseRoute path="/admin/tools/reports" redirect>
                  <AdminReports />
                </SafeleaseRoute>
                <SafeleaseRoute path="/admin/tools/billing" redirect>
                  <AdminBilling />
                </SafeleaseRoute>
                <SafeleaseRoute path="/admin/tools/billing_report" redirect>
                  <AdminBillingReport />
                </SafeleaseRoute>
                <SafeleaseRoute path="/admin/tools/match_claims" redirect>
                  <MatchClaims routerHelper={routerHelper} />
                </SafeleaseRoute>
                <SafeleaseRoute path="/admin/tools/attach_rate_report" redirect>
                  <AttachRateReport />
                </SafeleaseRoute>
                <SafeleaseRoute path="/admin/tools/job_health" redirect>
                  <JobHealth />
                </SafeleaseRoute>
                <SafeleaseRoute path="/admin/tools/user_info" redirect>
                  <UserInfoTable />
                </SafeleaseRoute>
                <SafeleaseRoute
                  path="/admin/tools/credentials/:locationId"
                  redirect
                >
                  <Credentials />
                </SafeleaseRoute>
                <SafeleaseRoute
                  path="/admin/tools/billing_credentials/:llcId"
                  redirect
                >
                  <BillingCredentials />
                </SafeleaseRoute>
                <SafeleaseRoute path="/admin/tools/emails" redirect>
                  <UpcomingEmails />
                </SafeleaseRoute>
                <SafeleaseRoute path="/admin/retry/:jobId" redirect>
                  <RetryJob />
                </SafeleaseRoute>
                <SafeleaseRoute path="/admin/notes/:relationshipId" redirect>
                  <Notes />
                </SafeleaseRoute>
                <SafeleaseRoute
                  path="/admin/invoices/recurring_invoice_line_items/:relationshipId"
                  redirect
                >
                  <RecurringInvoiceLineItems />
                </SafeleaseRoute>
                <SafeleaseRoute path="/admin/invoices/:relationshipId" redirect>
                  <Invoices />
                </SafeleaseRoute>
                <SafeleaseRoute path="/admin/tools/impact" redirect>
                  <ImpactReport />
                </SafeleaseRoute>
                <SafeleaseRoute path="/admin/tools/error_center" redirect>
                  <JobsErrorCenter routerHelper={routerHelper} />
                </SafeleaseRoute>
                <SafeleaseRoute
                  path="/admin/tools/engineering_error_report"
                  redirect
                >
                  <EngineeringErrorReport />
                </SafeleaseRoute>
                <SafeleaseRoute path="/admin/tools/map" redirect>
                  <LocationsMap />
                </SafeleaseRoute>
                <SafeleaseRoute path="/admin/tools/general_units" redirect>
                  <GeneralUnitsHistory routerHelper={routerHelper} />
                </SafeleaseRoute>
                <SafeleaseRoute path="/admin/tools/plan_upgrades" redirect>
                  <PlanUpgrades />
                </SafeleaseRoute>
                <SafeleaseRoute path="/admin/tools/compliance_center" redirect>
                  <ComplianceCenter />
                </SafeleaseRoute>
                <SafeleaseRoute path="/admin/tools" redirect>
                  <AdminTools />
                </SafeleaseRoute>

                <SafeleaseRoute path="/admin/otp/enroll" redirect>
                  <OtpEnroll />
                </SafeleaseRoute>

                <SafeleaseRoute
                  path="/admin/salesforce/sync_opportunity_location/:opportunityLocationId"
                  redirect
                >
                  <SyncOnOpportunityLocationId />
                </SafeleaseRoute>

                <SafeleaseRoute
                  path="/admin/salesforce/sync_opportunity/:opportunityId"
                  redirect
                >
                  <SyncOnOpportunityId />
                </SafeleaseRoute>

                <SafeleaseRoute
                  exact
                  path="/"
                  redirect={!routerHelper.getIsInsuranceUser()}
                  redirectTo={
                    routerHelper.getIsInsightsUser() ? "/insights" : "/settings"
                  }
                >
                  <AuditRouteWrapper routerHelper={routerHelper}>
                    <Dashboard routerHelper={routerHelper} />
                  </AuditRouteWrapper>
                </SafeleaseRoute>
                <SafeleaseRoute path="/claim/:claimId" redirect={!routerHelper.getIsInsuranceUser()}>
                  <Dashboard routerHelper={routerHelper} />
                </SafeleaseRoute>
                <SafeleaseRoute exact path="/admin/:relationshipId/claim/:claimId" redirect>
                  <Dashboard routerHelper={routerHelper} />
                </SafeleaseRoute>
                <SafeleaseRoute exact path="/admin/:relationshipId" redirect>
                  <Dashboard routerHelper={routerHelper} />
                </SafeleaseRoute>

                <QueryClientProvider client={reactQueryClient}>
                  <ThemeProvider theme={theme}>
                    <SafeleaseRoute exact path="/admin/reputation/:relationshipId" redirect redirectTo="/reputation">
                      <ReputationManagement routerHelper={routerHelper} />
                    </SafeleaseRoute>
                    <SafeleaseRoute exact path="/admin/reputation/:relationshipId/facility/:facilityId" redirect redirectTo="/reputation/facility/:facilityId">
                      <FacilityView routerHelper={routerHelper}/>
                    </SafeleaseRoute>                    
                    <SafeleaseRoute exact path="/reputation">
                      <ReputationManagement routerHelper={routerHelper} />
                    </SafeleaseRoute>
                    <SafeleaseRoute exact path="/reputation/facility/:facilityId">
                      <FacilityView routerHelper={routerHelper}/>
                    </SafeleaseRoute>
                  </ThemeProvider>
                </QueryClientProvider>
                
                <SafeleaseRoute>Not found</SafeleaseRoute>
              </Switch>
            </div>
          </div>

          <footer>
            &copy; SafeLease Insurance Services, LLC {new Date().getFullYear()}.
            All rights reserved
          </footer>
        </div>
      </div>
    </NotificationsProvider>
  );
}

export default App;
