import { useEffect, useMemo } from "react";

// UI - libs
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  Grid,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { ExpandMore } from "@mui/icons-material";

// Hooks
import { usePlanUpgradeStore } from "../usePlanUpgradeSettingsStore";

// Utilities
import { Formatters } from "../../../utilities/formatters";
import dayjs from "dayjs";
import { QueryResult, useQuery } from "@apollo/client";
import { GeneralUnit } from "../../../utilities/generated/gql-types";
import { getGeneralUnits } from "../../../queries";
import { calculateMigration, skuConfig } from "./skuConfig";
import { ReviewStepSKUTransitionRow } from "./ReviewStepSKUTransitionRow";

export type PlanUpgradeStepWithUnitCount = PlanUpgradeStep & {
  numberAtPremiumBefore: number;
};

interface ReviewStepProps {
  confirmedSteps: {
    [key: string]: boolean;
  };
  setConfirmedSteps: React.Dispatch<
    React.SetStateAction<{
      [key: string]: boolean;
    }>
  >;
}

/* Give the user a chance to review before submitting */
export function ReviewStep({
  confirmedSteps,
  setConfirmedSteps,
}: ReviewStepProps) {
  /* FORM STATE */
  const selectedRelationship = usePlanUpgradeStore(
    (state) => state.selectedRelationship
  );
  const selectedLocations = usePlanUpgradeStore(
    (state) => state.selectedLocations
  );
  const enforcementDate = usePlanUpgradeStore((state) => state.enforcementDate);
  const premiumBuckets = usePlanUpgradeStore((state) => state.premiumBuckets);
  const setSKUsAtSelectedLocations = usePlanUpgradeStore(
    (state) => state.setSKUsAtSelectedLocations
  );
  const migrationOmissions = usePlanUpgradeStore(
    (state) => state.omitPlanUpgradeSteps
  );

  const planUpgradeSteps = usePlanUpgradeStore(
    (state) => state.planUpgradeSteps
  );

  const generalUnitsQuery: QueryResult<{ getGeneralUnits: GeneralUnit[] }> =
    useQuery(getGeneralUnits, {
      variables: {
        relationshipId: selectedRelationship?.id,
        occupied: true,
        policyType: "safelease",
      },
      skip: !selectedRelationship
    }
  );

  /* General units, filtered to only include the selected locations */
  const generalUnits = useMemo(() => {
    return (
      generalUnitsQuery?.data?.getGeneralUnits?.filter((unit) =>
        selectedLocations
          .map((location) => Number(location.id))
          .includes(unit.locationId)
      ) ?? []
    );
  }, [generalUnitsQuery?.data?.getGeneralUnits, selectedLocations]);

  /* Confirms the review of a step */
  const handleConfirmStep = (step: string) => (event: React.SyntheticEvent) => {
    event.stopPropagation();
    event.preventDefault();
    setConfirmedSteps((prev) => ({ ...prev, [step]: !prev[step] }));
  };

  /* State of the unit coverage/premium before any changes */
  const skuConfigBefore = useMemo(
    () =>
      skuConfig(
        generalUnits,
        premiumBuckets.map((premiumBucket) => ({
          premium: premiumBucket.newPremium,
          coverage: premiumBucket.newCoverage,
        })),
        "before"
      ),
    [generalUnits, premiumBuckets]
  );

  /* State of the unit coverage/premium before any changes */
  const skuConfigAfter = useMemo(
    () =>
      skuConfig(
        generalUnits,
        premiumBuckets.map((premiumBucket) => ({
          premium: premiumBucket.newPremium,
          coverage: premiumBucket.newCoverage,
        })),
        "after"
      ),
    [generalUnits, premiumBuckets]
  );

  /* Get a breakdown of how many units are moving between SKUs */
  const migrations: PlanUpgradeStepWithUnitCount[] = useMemo(
    () =>
      calculateMigration(
        skuConfigBefore,
        premiumBuckets.map((premiumBucket) => ({
          premium: premiumBucket.newPremium,
          coverage: premiumBucket.newCoverage,
        }))
      )
        .filter(
          (migration) =>
            !(
              migration.oldCoverage === migration.newCoverage &&
              migration.oldPremium === migration.newPremium
            )
        )
        .sort((a, b) => a.oldPremium - b.newPremium),
    [skuConfigBefore, skuConfigAfter]
  );

  useEffect(() => {
    /* Update the SKUs at the selected locations */
    setSKUsAtSelectedLocations(skuConfigAfter.skusAtLocations);
  }, [skuConfigAfter]);

  const countImpactedUnits = useMemo(() => {
    return migrations.reduce((acc, migration) => {
      const isIncluded =
        migrationOmissions.findIndex(
          (omission) =>
            omission.oldCoverage === migration.oldCoverage &&
            omission.oldPremium === migration.oldPremium
        ) === -1;

      if (isIncluded) return acc + migration.numberAtPremiumBefore;
      return acc;
    }, 0);
  }, [migrations, migrationOmissions]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Accordion elevation={0} sx={{ border: "1px solid #EBEFF7" }}>
          <AccordionSummary sx={{ pl: 0 }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ width: "100%" }}
            >
              <Stack direction="row" alignItems="center" spacing={0.5}>
                <Checkbox
                  checked={confirmedSteps.relationship}
                  onChange={handleConfirmStep("relationship")}
                  onClick={(event) => event.stopPropagation()}
                />
                <Typography
                  variant="body1"
                  sx={{ color: grey[800], fontWeight: "bold" }}
                >
                  Relationship
                </Typography>
              </Stack>
              <Typography>{selectedRelationship?.name}</Typography>
            </Stack>
          </AccordionSummary>
        </Accordion>
      </Grid>
      <Grid item xs={12}>
        <Accordion elevation={0} sx={{ border: "1px solid #EBEFF7" }}>
          <AccordionSummary expandIcon={<ExpandMore />} sx={{ pl: 0 }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ width: "100%" }}
            >
              <Stack direction="row" alignItems="center" spacing={0.5}>
                <Checkbox
                  checked={confirmedSteps.locations}
                  onChange={handleConfirmStep("locations")}
                  onClick={(event) => event.stopPropagation()}
                />
                <Typography
                  variant="body1"
                  sx={{ color: grey[800], fontWeight: "bold" }}
                >
                  Selected Locations
                </Typography>
              </Stack>
              <Typography sx={{ mr: 2 }}>{selectedLocations.length}</Typography>
            </Stack>
          </AccordionSummary>
          <AccordionDetails>
            <List>
              {selectedLocations.map((location, index) => (
                <ListItem key={index}>
                  <ListItemText primary={`${index + 1}. ${location.address}`} />
                </ListItem>
              ))}
            </List>
          </AccordionDetails>
        </Accordion>
      </Grid>
      <Grid item xs={12}>
        <Accordion elevation={0} sx={{ border: "1px solid #EBEFF7" }}>
          <AccordionSummary expandIcon={<ExpandMore />} sx={{ pl: 0 }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ width: "100%" }}
            >
              <Stack direction="row" alignItems="center" spacing={0.5}>
                <Checkbox
                  checked={confirmedSteps.plannedUpgrades}
                  onChange={handleConfirmStep("plannedUpgrades")}
                  onClick={(event) => event.stopPropagation()}
                />
                <Typography
                  variant="body1"
                  sx={{ color: grey[800], fontWeight: "bold" }}
                >
                  Planned Upgrades
                </Typography>
              </Stack>
              <Typography sx={{ mr: 2 }}>
                Impacts{" "}
                {Formatters.formatWithCommas(countImpactedUnits)}{" "}
                units
              </Typography>
            </Stack>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="caption" sx={{ color: grey[600] }}>
              Consolidating units upward into the following buckets:
            </Typography>
            <List>
              {premiumBuckets.map((bucket, index) => (
                <ListItem key={index}>
                  <ListItemText
                    primary={`${Formatters.penniesToDollars(
                      bucket.newCoverage
                    )} coverage for ${Formatters.penniesToDollars(
                      bucket.newPremium
                    )} premium`}
                  />
                </ListItem>
              ))}
            </List>
            <Typography variant="caption" sx={{ color: grey[600] }}>
              Premium/Coverage Movement Breakdown:
            </Typography>
            <Grid container spacing={1}>
              <Grid item xs={1} textAlign="center">
                <Typography fontWeight="bold" variant="caption">
                  Included
                </Typography>
              </Grid>
              <Grid item xs={1} textAlign="right">
                <Typography fontWeight="bold" variant="caption">
                  Units
                </Typography>
              </Grid>
              <Grid item xs={2} textAlign="right">
                <Typography fontWeight="bold" variant="caption">
                  Premium Before
                </Typography>
              </Grid>
              <Grid item xs={2} textAlign="right">
                <Typography fontWeight="bold" variant="caption">
                  Coverage Before
                </Typography>
              </Grid>
              <Grid item xs={2} textAlign="right">
                <Typography fontWeight="bold" variant="caption">
                  Premium After
                </Typography>
              </Grid>
              <Grid item xs={2} textAlign="right">
                <Typography fontWeight="bold" variant="caption">
                  Coverage After
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography fontWeight="bold" variant="caption">
                  Impacted Locations
                </Typography>
              </Grid>
              {migrations.length === 0 && (
                <Grid item xs={12} textAlign="center">
                  <Typography variant="caption">
                    No units are moving between SKUs
                  </Typography>
                </Grid>
              )}
              {migrations.map((migration, index) => (
                <ReviewStepSKUTransitionRow
                  key={index}
                  readOnly
                  migration={migration}
                  skuConfigAfter={skuConfigAfter}
                />
              ))}
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
      <Grid item xs={12}>
        <Accordion elevation={0} sx={{ border: "1px solid #EBEFF7" }}>
          <AccordionSummary sx={{ pl: 0 }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ width: "100%" }}
            >
              <Stack direction="row" alignItems="center" spacing={0.5}>
                <Checkbox
                  checked={confirmedSteps.enforcementDate}
                  onChange={handleConfirmStep("enforcementDate")}
                  onClick={(event) => event.stopPropagation()}
                />
                <Typography
                  variant="body1"
                  sx={{ color: grey[800], fontWeight: "bold" }}
                >
                  Effective Date
                </Typography>
              </Stack>
              <Typography>
                {dayjs(enforcementDate).format("MMMM DD YYYY")}
              </Typography>
            </Stack>
          </AccordionSummary>
        </Accordion>
      </Grid>
    </Grid>
  );
}
