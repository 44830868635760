import { useQuery as useQuery_Rest } from "@tanstack/react-query";
import { useState } from "react";
import { useReputation } from "../../../../../useReputation";
import { SafeLeaseButton, SafeLeaseCard, SafeLeaseChip } from "@safelease/components";
import { Stack, Theme } from "@mui/system";
import { Alert, Divider, Typography } from "@mui/material";
import { ErrorOutlineOutlined } from "@mui/icons-material";
import { TwilioSubAccount_Reputation } from "@safelease/service-utilities";
import { enqueueSnackbar } from "notistack";
import { FacilityTwilioData } from "../../../../../types/FacilityTwilioData";
import SetupDataService from "../../../../../../services/setup.service";
import { SafeLeaseLocationTwilioDataResponse } from "../../../../../types/SafeLeaseLocationTwilioDataResponse";
import { CircularProgress } from "@mui/material";
import { reactQueryClient } from "../../../../../../utilities/reactQueryClent";

/*********
 * Types *
 *********/
interface ErrorInstance {
  code: string;
  message: string;
  path: string[];
  validation: string;
}

interface DataErrorResponse {
  error: ErrorInstance[];
  message: string;
}

enum TwilioSetupStatus {
  NOT_STARTED = "NOT_STARTED",
  READY_TO_SUBMIT = "READY_TO_SUBMIT",
  SUBMITTED = "SUBMITTED",
  IN_PROGRESS = "IN_PROGRESS",
  VERIFIED = "VERIFIED",
  FAILED = "FAILED",
  UNKNOWN = "UNKNOWN_STATUS",
}

interface Property {
  label: string;
  fields: string[];
  editLink: string;
  dataObj: any;
}

interface PropertiesHolder {
  location: Property;
  llc: Property;
  llcPointOfContact: Property;
}

/*************
 * Constants *
 *************/
enum PropertyType {
  LOCATION = "location",
  LLC = "llc",
  LLC_POINT_OF_CONTACT = "llcPointOfContact",
}

const propertiesOrder = [PropertyType.LOCATION, PropertyType.LLC, PropertyType.LLC_POINT_OF_CONTACT];

const propertiesHolder: PropertiesHolder = {
  location: {
    label: "Location",
    fields: ["id", "name", "landingWebsiteUrl", "privacyPolicyUrl", "textingOptInInstructions"],
    editLink: "/settings/organization",
    dataObj: null,
  },
  llc: {
    label: "LLC",
    fields: ["id", "name", "businessType", "address", "city", "state", "postalCode"],
    editLink: "/settings/organization",
    dataObj: null,
  },
  llcPointOfContact: {
    label: "LLC Point of Contact",
    fields: ["name", "email", "phone", "jobTitle", "jobPosition"],
    editLink: "/settings/my-account",
    dataObj: null,
  },
};

/*************
 * Component *
 *************/
export const TwilioVerificationCard = () => {
  const [syncing, setSyncing] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [errors, setErrors] = useState<string[]>([]);
  const { facilityTwilioData, facility, SafeLeaseAPI } = useReputation();
  const [checked, setChecked] = useState<boolean>(false);

  /**************
   * Fetch Data *
   **************/
  const getSafeleaseLocationTwilioData = async (locationId) => {
    const response = await SetupDataService.getTwilioSetupData(locationId);
    return response.data;
  };
  const safeleaseLocationTwilioDataQuery = useQuery_Rest({
    queryKey: ["safeleaseLocationTwilioData", facility.customerLocationId],
    queryFn: () => getSafeleaseLocationTwilioData(facility.customerLocationId),
    enabled: facility.customerLocationId !== null,
  });

  if (safeleaseLocationTwilioDataQuery.isLoading) return <div>Loading...</div>;
  if (safeleaseLocationTwilioDataQuery.isError) return <div>Error</div>;

  /**************
   * Parse Data *
   **************/
  const locationTwilioData: SafeLeaseLocationTwilioDataResponse = safeleaseLocationTwilioDataQuery.data;
  propertiesHolder.location.dataObj = { ...locationTwilioData };

  const llc = locationTwilioData.Llc;
  propertiesHolder.llc.dataObj = llc;

  const LlcPoc = {};
  if (llc?.User) {
    LlcPoc["jobTitle"] = llc.User.jobTitle;
    LlcPoc["jobPosition"] = llc.User.jobPosition;
  }
  if (llc?.User?.AuthUser) {
    LlcPoc["name"] = llc.User.AuthUser.name;
    LlcPoc["email"] = llc.User.AuthUser.email;
    LlcPoc["phone"] = llc.User.AuthUser.phone;
  }
  propertiesHolder.llcPointOfContact.dataObj = LlcPoc;

  /****************
   * Setup Status *
   ****************/
  const twilioSetupStatusForFacility = (facility: FacilityTwilioData) => {
    const tbe = facility.twilioBusinessEntity;
    if (!tbe?.twilioSubAccount) return TwilioSetupStatus.NOT_STARTED;
    // READY TO SUBMIT
    if (tbe.twilioSubAccount && !tbe.twilioSubAccount.brandRegistrationSid) return TwilioSetupStatus.READY_TO_SUBMIT;
    // SUBMITTED
    const tsa: TwilioSubAccount_Reputation = tbe.twilioSubAccount;
    if (tsa.brandRegistrationSid && !tsa.a2pCampaignApprovalStatus) return TwilioSetupStatus.SUBMITTED;
    // IN PROGRESS
    if (tsa.a2pCampaignApprovalStatus === "IN_PROGRESS") return TwilioSetupStatus.IN_PROGRESS;
    // FAILED
    if (tsa.a2pCampaignApprovalStatus === "FAILED") return TwilioSetupStatus.FAILED;
    // VERIFIED
    if (tsa.a2pCampaignApprovalStatus === "VERIFIED") return TwilioSetupStatus.VERIFIED;

    return TwilioSetupStatus.UNKNOWN;
  };

  const setupStatus = twilioSetupStatusForFacility(facilityTwilioData);
  const setupStatusChip = (setupStatus) => {
    const colorMap = {
      [TwilioSetupStatus.VERIFIED]: "success",
      [TwilioSetupStatus.FAILED]: "error",
    };
    const chipColor = colorMap[setupStatus] || "default";
    return <SafeLeaseChip color={chipColor} label={setupStatus} sx={{ borderRadius: "50em" }} />;
  };

  /******************
   * Click Handlerz *
   ******************/
  const handleDataRefresh = async () => {
    safeleaseLocationTwilioDataQuery.refetch();
    setErrors([]);
  };

  const handleCheckClick = async () => {
    let newErrors: string[] = [];
    setSyncing(true);
    try {
      await SafeLeaseAPI.reputation.syncTwilioDataForEnablingSmsOnFacility({ facilityId: facility.id });
      await reactQueryClient.invalidateQueries({ queryKey: ["facilityTwilioData", facility.id] });
      enqueueSnackbar("Data is ready to submit to Twilio.", { variant: "success" });
    } catch (error: any) {
      const errorData: DataErrorResponse | undefined = error?.response?.data;
      const errorMessages = errorData?.error?.map((e) => `${e.path.join(".")} - ${e.message}`) || [];
      newErrors = [...newErrors, ...errorMessages];

      // Default message in case no error messages are returned
      if (newErrors.length === 0) {
        newErrors.push("Unknown error occurred. Please contact support.");
      }

      enqueueSnackbar(errorData?.message || "Error syncing Twilio data. Please contact support.", { variant: "error" });
    } finally {
      setErrors(newErrors);
      setSyncing(false);
    }
  };

  const handleCheckClickedForFailedStatus = async () => {
    setChecked(true);
    handleCheckClick();
  };

  const handleSubmitClick = async () => {
    setSubmitting(true);
    try {
      await SafeLeaseAPI.reputation.submitTwilioBusinessEntityForVerification({
        twilioBusinessEntityId: facilityTwilioData.twilioBusinessEntity.id,
      });
      await reactQueryClient.invalidateQueries({ queryKey: ["facilityTwilioData", facility.id] });
      enqueueSnackbar("Twilio data submitted for verification successfully.", { variant: "success" });
    } catch (error) {
      enqueueSnackbar("Error submitting twilio data for verification. Please contact support.", { variant: "error" });
    } finally {
      setSubmitting(false);
    }
  };

  // this function is used to retry the A2P campaign submission in a failed state.
  const retryA2PCampaignSubmission = async () => {
    if (!checked) {
      enqueueSnackbar("Please click the check button first.", { variant: "error" });
      return;
    }
    if (checked) {
      setSubmitting(true);
      try {
        await SafeLeaseAPI.reputation.updateA2PCampaign({
          twilioBusinessEntityId: facilityTwilioData.twilioBusinessEntity.id,
        });
        enqueueSnackbar("A2P campaign updated successfully.", { variant: "success" });
      } catch (error) {
        console.error(error);
        enqueueSnackbar("Error updating A2P campaign. Please contact support.", { variant: "error" });
      } finally {
        setSubmitting(false);
      }
    }
  };

  /******
   * UI *
   ******/
  return (
    <>
      <SafeLeaseCard
        sx={{
          p: 2,
          cursor: "pointer",
          transition: "0.15s ease-in-out",
          height: "100%",
        }}
      >
        <Stack direction={"column"} spacing={1}>
          {/* Title & Buttons Header */}
          <Stack direction={"row"} justifyContent={"space-between"} spacing={1}>
            <Stack>
              <Typography variant="h6">Twilio Setup {setupStatusChip(setupStatus)} </Typography>
            </Stack>

            {setupStatus === TwilioSetupStatus.FAILED ? (
              <Stack direction={"row"}>
                <SafeLeaseButton onClick={handleDataRefresh}>Refresh</SafeLeaseButton>
                <SafeLeaseButton onClick={handleCheckClickedForFailedStatus}>
                  {syncing ? <CircularProgress size={20} /> : "Check"}
                </SafeLeaseButton>
                <SafeLeaseButton onClick={retryA2PCampaignSubmission}>
                  {submitting ? <CircularProgress size={20} /> : "Retry Submission"}
                </SafeLeaseButton>
              </Stack>
            ) : (
              <Stack direction={"row"}>
                <SafeLeaseButton onClick={handleDataRefresh} disabled={setupStatus !== TwilioSetupStatus.NOT_STARTED}>
                  Refresh
                </SafeLeaseButton>
                <SafeLeaseButton onClick={handleCheckClick} disabled={setupStatus !== TwilioSetupStatus.NOT_STARTED}>
                  {syncing ? <CircularProgress size={20} /> : "Check"}
                </SafeLeaseButton>
                <SafeLeaseButton onClick={handleSubmitClick} disabled={setupStatus !== TwilioSetupStatus.READY_TO_SUBMIT}>
                  {submitting ? <CircularProgress size={20} /> : "Submit"}
                </SafeLeaseButton>
              </Stack>
            )}
          </Stack>

          {/* Location, LLC, Authorized Rep area */}
          <Stack direction={"row"} justifyContent={"flex-start"} spacing={1}>
            {propertiesOrder.map((prop) => {
              const propertyObj: Property = propertiesHolder[prop];
              return (
                <SafeLeaseCard key={propertyObj.label} sx={{ p: 2, borderRadius: 1 }}>
                  <Stack direction={"column"} spacing={1} width={"350px"}>
                    <Typography variant="body1" fontWeight={600} onClick={() => window.open(propertyObj.editLink, "_blank")}>
                      {propertyObj.label}
                    </Typography>
                    <Divider orientation="horizontal" sx={{ color: (theme: Theme) => theme.palette.grey.A100, mx: 3 }} flexItem />
                    <Stack direction={"column"} spacing={1}>
                      {propertyObj.fields.map((field) => {
                        const val = propertyObj.dataObj[field];
                        return (
                          <Stack direction="row" justifyContent="space-between" spacing={1} key={`${propertyObj}-${field}`}>
                            <Typography fontWeight={400} color={val ? "black" : "rgb(178,34,34)"}>
                              {field}
                            </Typography>
                            <Typography variant="caption" fontSize={13}>
                              {val}
                            </Typography>
                          </Stack>
                        );
                      })}
                    </Stack>
                  </Stack>
                </SafeLeaseCard>
              );
            })}
          </Stack>

          {/* Errors */}
          {errors.length > 0 && (
            <Alert severity="error" icon={false} sx={{ borderRadius: 1, padding: 1, alignItems: "left" }}>
              <Stack
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start"
                sx={{ color: (theme: Theme) => theme.palette.grey.A100, padding: 0 }}
              >
                {errors.map((error) => (
                  <Typography key={error} variant="subtitle2">
                    <ErrorOutlineOutlined sx={{ fontSize: 16 }} /> {error}
                  </Typography>
                ))}
              </Stack>
            </Alert>
          )}
        </Stack>
      </SafeLeaseCard>
    </>
  );
};
