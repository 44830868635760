import RouterHelper from "../utilities/router-helper";
import { NavIcons } from "../nav-bar/icons";

export interface NavigationRoute {
  path: string;
  label: string;
  icon: any; // TODO dont use any ya dumb bum
  shouldHaveDrawerItem?: boolean;
  hasPermission?: boolean;
  chipVariant?: "new" | "beta";
  children?: NavigationRoutes;
  hasNotification?: boolean;
  hide?: boolean;
}

type NavigationRoutes = NavigationRoute[];

export const routes: (routerHelper: RouterHelper, notificationsContext: any, hiddenRoutes: any, isAdmin: boolean) => NavigationRoutes = (
  routerHelper,
  notificationsContext = {},
  hiddenRoutes = {},
  isAdmin,
) => [
  {
    path: routerHelper.dashboardRoute(),
    label: "Dashboard",
    icon: NavIcons.DashboardIcon,
    shouldHaveDrawerItem: true,
    hasPermission: routerHelper.getIsInsuranceUser(),
    hasNotification: notificationsContext[routerHelper.dashboardRoute()],
    hide: hiddenRoutes[routerHelper.dashboardRoute()],
  },
  {
    path: routerHelper.insightsRoute(null),
    label: "Insights",
    icon: NavIcons.InsightsIcon,
    hasPermission: routerHelper.getIsInsightsUser(),
    shouldHaveDrawerItem: true,
    hasNotification: notificationsContext[routerHelper.insightsRoute(null)],
    hide: hiddenRoutes[routerHelper.insightsRoute(null)],
  },
  {
    path: routerHelper.reportsRoute(),
    label: "Reports",
    icon: NavIcons.ReportsIcon,
    hasPermission: true,
    shouldHaveDrawerItem: true,
    hasNotification: notificationsContext[routerHelper.reportsRoute()],
    hide: hiddenRoutes[routerHelper.reportsRoute()],
  },
  {
    path: "/knowledge-center",
    label: "Knowledge Center",
    icon: NavIcons.KnowledgeCenterIcon,
    hasPermission: true,
    shouldHaveDrawerItem: true,
    hide: hiddenRoutes["/knowledge-center"],
  },
  {
    path: routerHelper.documentsRoute(),
    label: "Documents",
    icon: NavIcons.DocumentManagerIcon,
    hasPermission: true,
    shouldHaveDrawerItem: true,
    hasNotification: notificationsContext[routerHelper.documentsRoute()],
    hide: hiddenRoutes[routerHelper.documentsRoute()],
  },
  {
    path: "/settings",
    label: "Settings",
    icon: NavIcons.GearIcon,
    hasPermission: true,
    shouldHaveDrawerItem: true,
    hide: hiddenRoutes["/settings"],
  },
  {
    path: "/admin/relationships",
    label: "Relationships",
    icon: NavIcons.PartnersIcon,
    hasPermission: true,
    shouldHaveDrawerItem: true,
    hide: hiddenRoutes["/admin/relationships"],
  },
  {
    path: routerHelper.planUpgradesRoute(),
    label: "Plan Upgrades",
    icon: NavIcons.PlanUpgradesIcon,
    shouldHaveDrawerItem: true,
    chipVariant: "beta",
    hide: hiddenRoutes[routerHelper.planUpgradesRoute()],
  },
  {
    path: routerHelper.complianceRoute(),
    label: "Compliance",
    icon: NavIcons.ComplianceIcon,
    hasPermission: true,
    shouldHaveDrawerItem: true,
    hasNotification: notificationsContext[routerHelper.complianceRoute()],
    hide: hiddenRoutes[routerHelper.complianceRoute()],
  },
  {
    path: routerHelper.reputationRoute(),
    label: "Reputation",
    icon: NavIcons.ReputationIcon,
    hasPermission: true,
    shouldHaveDrawerItem: true,
    chipVariant: "new",
    hide: hiddenRoutes[routerHelper.reputationRoute()],
  },
  {
    path: "/admin/tools",
    label: "Admin Tools",
    icon: NavIcons.ClaimsIcon,
    hasPermission: true,
    shouldHaveDrawerItem: true,
    hide: hiddenRoutes["/admin/tools"],
  },
  // {
  //   path: routerHelper.vendorSourcingRoute(),
  //   label: "Vendor Sourcing",
  //   icon: NavIcons.VendorSourcing,
  //   hasPermission: true,
  //   shouldHaveDrawerItem: true,
  //   chipVariant: "beta",
  //   hide: hiddenRoutes[routerHelper.vendorSourcingRoute()],
  // },
];
