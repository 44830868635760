import { Link } from "react-router-dom";
import RouterHelper from "../utilities/router-helper";
import { NavigationRoute } from "./routes";
import { Chip, ListItem, ListItemIcon, ListItemText, alpha } from "@mui/material";
import { NavBarNotificationDot } from "./NavigationDrawerNotificationDot";
import * as NavIcons from "../nav-bar/icons";
import { blue, green } from "@mui/material/colors";
import { mixpanelEventHandler } from "../utilities/reactMixpanelHandler";

interface NavigationDrawerListItemProps extends NavigationRoute {
  /* Label for the item */
  label: string;

  /* Display the red dot */
  hasNotification?: boolean;
  routerHelper: RouterHelper;

  /* Used to determine the color of the icon in the drawer */
  setIcon: () => void;
  icon: any;
  selectedIcon: any;

  /* If not null, render chip next to text */
  chipVariant?: "new" | "beta";
  hide?: boolean;
}

export function NavigationDrawerListItem({
  path,
  label,
  icon,
  shouldHaveDrawerItem = false,
  hasNotification,
  selectedIcon,
  setIcon,
  chipVariant,
  hide = false,
}: NavigationDrawerListItemProps) {
  /* Is this menu item related to the current page / route */
  const isSelected = selectedIcon === icon;

  /* For future use, all routes will live in one file and only ones with this flag
   * will be displayed in the drawer */
  if (!shouldHaveDrawerItem || hide) return null;

  const handleClick = () => {
    mixpanelEventHandler("Navigation Drawer Click", {
      item: label,
      hasNotification,
      hasChip: Boolean(chipVariant),
    });
    setIcon();
  };

  return (
    <Link to={path} style={{ textDecoration: "none" }}>
      <ListItem
        onClick={handleClick}
        key={label}
        sx={{
          color: "white",
          py: 2,
          px: 2,
          bgcolor: isSelected ? "#091323" : "initial",
          cursor: "pointer",
          "&:hover": {
            bgcolor: "#0E1C33",
          },
        }}
      >
        <ListItemIcon sx={{ color: isSelected ? "#2879fb" : "white", minWidth: 44 }}>
          {NavIcons[icon]()}
          {hasNotification && (
            <NavBarNotificationDot selectedIcon={selectedIcon} icon={NavIcons[icon]} isHovered={false} isSelected={isSelected} />
          )}
        </ListItemIcon>
        <ListItemText primary={label} primaryTypographyProps={{ fontSize: 12, fontFamily: "Open Sans" }} />
        <ListItemIcon>
          {chipVariant === "beta" && (
            <Chip
              label="BETA"
              sx={{
                backgroundColor: "rgba(156, 204, 101, 0.3)",
                color: green[300],
                fontWeight: "bold",
                height: "20px",
                border: `1px solid ${green[700]}`,
              }}
              size="small"
            />
          )}
          {chipVariant === "new" && (
            <Chip
              label="NEW"
              sx={{
                backgroundColor: alpha(blue[600], 0.3),
                color: blue[500],
                fontWeight: "bold",
                height: "20px",
                border: `1px solid ${blue[500]}`,
              }}
              size="small"
            />
          )}
        </ListItemIcon>
      </ListItem>
    </Link>
  );
}
