// UI - libs
import { Drawer, FormControl, IconButton, List, ListItem, MenuItem, Select, Tooltip, Typography } from "@mui/material";

// UI - internal
import { Image } from "../image";
import * as NavIcons from "../nav-bar/icons";
import { muiSelect, muiMenuItem } from "../styles/mui-overrides";

// Hooks
import { useEffect, useState } from "react";
import { NotificationsContext } from "../utilities/notifications-context";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { useAuth } from "../auth";
import useVisited from "../nav-bar/useVisited";

// Data
import { routes } from "./routes";
import { getPermissions } from "../queries";
import UserDataService from "../services/user.service";

// Utils
import RouterHelper from "../utilities/router-helper";
import { pathNameToIconMap } from "../nav-bar/nav-bar";
import { NavigationDrawerListItem } from "./NavigationDrawerListItem";
import { Box } from "@mui/system";
import { ChevronLeft, Menu } from "@mui/icons-material";

interface NavigationDrawerProps {
  routerHelper: RouterHelper;
}

/* Left-hand navigation drawer */
export function NavigationDrawer({ routerHelper }: NavigationDrawerProps) {
  const [selectedIcon, setSelectedIcon] = useState<NavIcons.NavIcons | null>(null);
  const [drawerOpen, setDrawerOpen] = useState(true);

  const auth = useAuth();
  const history = useHistory();
  const location = useLocation();
  const { hasVisited: hasVisitedCompliance } = useVisited("compliance");

  useEffect(() => {
    // Matches "/", "/admin/271", "/admin/271/claim/recgJFXfsdfsssfenOSqt" to Dashboard
    if (location.pathname === "/" || location.pathname.match(/^\/admin\/\d/)) {
      setSelectedIcon(NavIcons.NavIcons.DashboardIcon);
      return;
    }

    for (const pathName of Object.keys(pathNameToIconMap)) {
      if (location.pathname.startsWith(pathName)) {
        setSelectedIcon(pathNameToIconMap[pathName]);
        return;
      }
    }
  }, [location]);

  const permissions = useQuery(getPermissions, { skip: auth.user?.isAdmin });

  /* Dead code, probably.. is a permissions set selector. Last updated 2023-11-20 */
  async function changeActivePermission(event: any) {
    const response = await UserDataService.switchPermissions({
      permissionId: event.target.value,
    });

    if (response.data) {
      const user = { ...auth.user };
      user.relationshipId = response.data.relationshipId;
      user.role = response.data.role;
      user.locationIds = response.data.locationIds;
      await auth.signin(user);
      routerHelper.setRelationshipId(response.data.relationshipId);
      history.go(0);
    }
  }

  /* Set the currently selected icon for the route to turn it blue */
  function iconClicked(event: any, iconName: NavIcons.NavIcons) {
    setSelectedIcon(iconName);
  }

  /* Current options with notifications */
  const activeNotifications = (notificationsContext) => ({
    [routerHelper.dashboardRoute()]: notificationsContext.NotificationsHelper.hasClaimNoteNotification(
      notificationsContext.allNotifications
    ),
    [routerHelper.complianceRoute()]: !hasVisitedCompliance,
    [routerHelper.documentsRoute()]:
      notificationsContext.NotificationsHelper.hasInvoiceNotification(notificationsContext.allNotifications) > 0,
  });

  /* Conditionally hide routes based on specific conditions per listed in `nav-bar` */

  /* true = hide */
  const hiddenRoutes = {
    [routerHelper.dashboardRoute()]: !routerHelper.getIsInsuranceUser(),
    [routerHelper.insightsRoute(null)]: !routerHelper.getIsInsightsUser(),
    [routerHelper.reportsRoute()]: !routerHelper.getIsInsuranceUser(),
    "/admin/relationships": !auth.user?.isAdmin,
    "/admin/tools": !auth.user?.isAdmin,
  };

  if (!drawerOpen) {
    return (
      <Box sx={{ height: "100vh", bgcolor: "#152744" }}>
        <IconButton onClick={() => setDrawerOpen(true)}>
          <Menu fontSize="small" sx={{ color: "white" }} />
        </IconButton>
      </Box>
    );
  }

  return (
    <NotificationsContext.Consumer>
      {(notificationsContext) => (
        <Drawer
          variant="permanent"
          sx={{
            width: 240,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: 240,
              boxSizing: "border-box",
            },
            "&:hover .chevron-left": {
              visibility: "visible",
              cursor: "pointer",
              opacity: 1, // Fully visible on hover
              transition: "opacity .25s ease, visibility 0s ease 0s", // Fade in over 2 seconds
            },
          }}
          PaperProps={{
            sx: {
              bgcolor: "#152744",
            },
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "flex-end", paddingTop: 1, paddingRight: 1 }}>
            <Box
              className="chevron-left"
              sx={{
                opacity: 0, // Initially hidden (transparent)
                visibility: "hidden", // Hidden from layout initially
                transition: "opacity .5s ease, visibility 0s ease .5s", // Fade out over 5 seconds
              }}
            >
              <ChevronLeft onClick={() => setDrawerOpen(false)} sx={{ color: "grey" }} />
            </Box>
          </Box>
          <Link to="/" style={{ marginBottom: 20 }}>
            <Image
              style={{ marginLeft: 24, marginRight: 24 }}
              alt="SafeLease logo"
              src="/images/logos/horizontal/safelease_logohorizontal_rgb_blueonnavyblue.svg"
            />
          </Link>
          <List>
            {/* 99% sure this is dead code but it also lives in the original nav bar code */}
            {permissions?.data?.getPermissions.length > 1 && (
              <ListItem>
                <FormControl sx={{ marginTop: 1, minWidth: "80%", maxWidth: "100%" }}>
                  <Select
                    value={permissions.data.getPermissions.find((permission) => permission.relationshipId == auth.user.relationshipId)?.id}
                    displayEmpty
                    onChange={(e) => changeActivePermission(e)}
                    sx={{
                      ...muiSelect,
                      marginRight: "15px",
                      marginLeft: "15px",
                      padding: "0px",
                    }}
                  >
                    {permissions.data.getPermissions.map((permission) => (
                      <MenuItem sx={{ ...muiMenuItem, paddingLeft: "15px" }} key={permission.id} value={permission.id}>
                        {permission.relationshipName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </ListItem>
            )}
            {routes(routerHelper, activeNotifications(notificationsContext), hiddenRoutes, auth.user?.isAdmin).map((route) => (
              <NavigationDrawerListItem
                key={route.path}
                {...route}
                routerHelper={routerHelper}
                setIcon={() => iconClicked(null, route.icon)}
                selectedIcon={selectedIcon}
              />
            ))}
          </List>

          {auth.user?.isAdmin && (
            <Box sx={{ display: "flex", alignItems: "flex-end", height: "100%", width: "100%", padding: 2 }}>
              <Tooltip
                title={`${auth.user.name} you are viewing this app as an Admin. As an Admin you can access different relationships and manage settings that partners cannot.`}
              >
                <Typography variant="caption" color="grey" sx={{ paddingRight: 1, alignContent: "center" }}>
                  Admin Role Active
                </Typography>
              </Tooltip>
            </Box>
          )}
        </Drawer>
      )}
    </NotificationsContext.Consumer>
  );
}
