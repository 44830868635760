import { useState, useEffect } from "react";
import { MouseEvent } from "react";
import { NavIcons } from "./icons";
import { Link } from "react-router-dom";
import { NotificationDot } from "../common";
import { Chip, ChipTypeMap, Typography } from "@mui/material";
import { green } from "@mui/material/colors";

interface NavSelectionProps {
  title: string;
  children: any;
  icon: NavIcons;
  selectedIcon: NavIcons;
  linkTo: string | (() => string);
  onClick: (event: MouseEvent) => void;
  hasNotification?: boolean;
  displayNewChip?: boolean;
}

/* The Nav selection component is a menu list item in the primary navigation drawer */
function NavSelection({
  title,
  children,
  icon,
  selectedIcon,
  hasNotification,
  linkTo,
  onClick,
  displayNewChip = false,
}: NavSelectionProps) {
  const isSelected = selectedIcon === icon;

  const [isHovered, setIsHovered] = useState<boolean>(false);

  return (
    <Link
      to={linkTo}
      onMouseEnter={(event) => setIsHovered(true)}
      onMouseLeave={(event) => setIsHovered(false)}
      onClick={onClick}
      className="nav-bar--icons--link"
    >
      <div
        className={`nav-selection nav-bar--link ${
          isSelected ? "nav-bar--link__selected nav-selection__selected  " : ""
        }`}
      >
        <div className={hasNotification ? "tw--mr-[17px]" : ""}>
          {hasNotification && (
            <NavBarNotificationDot
              selectedIcon={selectedIcon}
              icon={icon}
              isHovered={isHovered}
            />
          )}
          {children}
        </div>
        <div
          className={`nav-selection--text ${
            isSelected ? "nav-selection--text__selected" : ""
          }`}
          style={{ width: '100%' }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: '100%'
            }}
          >
            {title}
            {displayNewChip && (
              <Chip
                label="NEW"
                sx={{
                  backgroundColor: "rgba(156, 204, 101, 0.3)",
                  color: green[300],
                  fontWeight: "bold",
                  height: "20px",
                  border: `1px solid ${green[700]}`,
                }}
                size="small"
              />
            )}
          </div>
        </div>
      </div>
    </Link>
  );
}

const NavBarNotificationDot = (props: {
  icon: NavIcons;
  selectedIcon: NavIcons;
  isSelected: boolean;
  isHovered: boolean;
}) => {
  const isSelected: boolean = props.selectedIcon === props.icon;

  return (
    <NotificationDot
      extraStyles={`tw-float-right tw-float-top 
        tw--top-2 tw--left-1.5 tw-relative tw-border-4 
        tw-border-solid tw-w-[17px] tw-h-[17px]
        ${
          isSelected
            ? "tw-border-[#091323]"
            : props.isHovered
            ? "tw-border-[#0A1C32]"
            : "tw-border-[#152744]"
        }`}
    />
  );
};

export default NavSelection;
