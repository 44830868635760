/* UI - Libs */
import { CircularProgress, Grid, Stack, Typography } from "@mui/material";

/* UI - internal */
import { PrivatePolicyReportOverview } from "./PrivatePolicyReportOverview";
import { PrivatePolicyReportDataGrid } from "./PrivatePolicyReportDataGrid";
import { CreatePrivatePolicy } from "./CreatePrivatePolicy";

/* Utils */
import "dayjs/plugin/timezone"; // .tz plugin on dayjs
import { useReportsStore } from "../../reports/useReportsStore";
import TitleHeader from "../../shared/title-header";
import ReportsLocationSelector from "./ReportsLocationSelector";
import RelationshipSelector from "../../shared/RelationshipSelector";
import RouterHelper from "../../utilities/router-helper";
import ReportsQueries from "../../reports/ReportsQueries";
import AccessControlled from "../../components/AccessControlled";
import { ComingSoonPage } from "../../reports/ComingSoonPage";
import { useAuth } from "../../auth";
import useVisited from "../../nav-bar/useVisited";
import { useEffect } from "react";

interface PrivatePolicyReportProps {
  routerHelper: RouterHelper;
}

/* Layout for the private policy report */
export function PrivatePolicyReport({
  routerHelper,
}: PrivatePolicyReportProps) {
  const auth = useAuth();

  const {
    handleOnClick: handleOnClickVisitedCompliance,
  } = useVisited("compliance");

  /* State */
  const setRelationshipId = useReportsStore((state) => state.setRelationshipId);
  const isReportLoading = useReportsStore((state) => state.loading);

  useEffect(() => {
    handleOnClickVisitedCompliance();
  }, [])

  return (
    <>
      <TitleHeader title="Compliance" />
      <Stack direction="row" justifyContent={"space-between"}>
        <Stack direction="row">
          {auth.user.isAdmin && (
            <RelationshipSelector
              routerHelper={routerHelper}
              nonRoute
              nonRouteCallback={setRelationshipId}
              disabled={isReportLoading}
            />
          )}
          <ReportsLocationSelector disabled={isReportLoading} />
        </Stack>
        {!isReportLoading && (
          <Stack direction="row">
            <CreatePrivatePolicy />
          </Stack>
        )}
      </Stack>
      <ReportsQueries routerHelper={routerHelper} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {isReportLoading && (
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              sx={{ height: 400 }}
            >
              <CircularProgress size={100} />
              <Typography variant="h4" sx={{ mt: 4, fontFamily: "Open Sans" }}>
                Putting together your report...
              </Typography>
            </Stack>
          )}
        </Grid>
        <Grid item xs={12}>
          {!isReportLoading && <PrivatePolicyReportOverview />}
        </Grid>
        <Grid item xs={12}>
          {!isReportLoading && <PrivatePolicyReportDataGrid />}
        </Grid>
      </Grid>
    </>
  );
}
