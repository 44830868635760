import { NotificationDot } from "../common";
import { NavIcons } from "../nav-bar/icons";

interface NavBarNotificationDotProps {
  icon: NavIcons;
  selectedIcon: NavIcons;
  isSelected: boolean;
  isHovered: boolean;
}

export const NavBarNotificationDot = ({
  icon,
  selectedIcon,
  isSelected,
  isHovered,
}: NavBarNotificationDotProps) => {

  return (
    <NotificationDot
      extraStyles={`tw-float-right tw-float-top 
        tw--top-2 tw--left-1.5 tw-relative tw-border-4 
        tw-border-solid tw-w-[17px] tw-h-[17px] 
        ${
          isSelected
            ? "tw-border-[#091323]"
            : isHovered
            ? "tw-border-[#0A1C32]"
            : "tw-border-[#152744]"
        }`}
    />
  );
};
