// UI - libs

import { Rating, Stack, Typography } from "@mui/material";
import { SafeLeaseCard, SafeLeaseChip } from "@safelease/components";

// Utils
import dayjs from "dayjs";
import { issueTypeLabels } from "./issueTypeLabels";

//Types
import { PrivateReview_Reputation as PrivateReview } from "@safelease/service-utilities";
import { FeedbackResponseFooter } from "../../../../components/FeedbackResponseActionArea";

interface FacilityFeedbackCardProps {
  feedback: PrivateReview;
}

/**
 * Returns a card that gives a detailed view of a private review submission
 * Includes the topics, review, and response status
 * The user can also click a reply button to open an email with the recipient prepopulated
 */
export function FacilityFeedbackCard({ feedback }: FacilityFeedbackCardProps) {
  return (
    <SafeLeaseCard sx={{ p: 2 }}>
      <Stack spacing={2}>
        <Stack direction="row" justifyContent="space-between" alignItems="flex-start" spacing={2}>
          <Typography>{feedback.facility?.companyName}</Typography>
          <Typography variant="body2" color="grey.A100">
            {dayjs(feedback.createdAt).format("MMM DD, YYYY")}
          </Typography>
        </Stack>
        <Stack direction="row" justifyContent="left" alignItems="center">
          {feedback.issueTypes.map((issueType: string) => (
            <SafeLeaseChip key={issueType} label={issueTypeLabels[issueType]} sx={{ mt: 1, mb: 1, mr: 1 }} color="neutral" />
          ))}
        </Stack>
        <Stack spacing={2}>
          <Rating readOnly value={feedback.rating} precision={1} />
          <Typography variant="body2">{feedback.feedback}</Typography>
          <FeedbackResponseFooter feedback={feedback} />
        </Stack>
      </Stack>
    </SafeLeaseCard>
  );
}
