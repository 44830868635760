import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { SafeleaseDropdown } from './common'
import { ProspectFms } from './utilities/field-enums'
import AdminDataService from "./services/admin.service";
import JobsDataService from "./services/jobs.service";
import { Link, useRouteMatch } from 'react-router-dom';
import { useQuery, useApolloClient } from '@apollo/client';
import {
  getAllLocations,
  getAllProspects,
  getAllLlcs,
  getAllEmails,
  me,
  getJob,
  getJobsErrorReport
} from './queries';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import FileSaver from 'file-saver';
import DatePicker from './components/lib/SafeLeaseDatePicker';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import { convertArrayToCSV } from 'convert-array-to-csv';
import TitleHeader from './shared/title-header';
import CircularProgress from '@mui/material/CircularProgress';
import { customAlphabet } from 'nanoid'
import QRCode from "react-qr-code";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import './styles/admin.scss';
import { muiTextField, muiRedButton } from "./styles/mui-overrides";
import { Box, IconButton, Stack, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckIcon from '@mui/icons-material/Check';
import SortingTable from './shared/sorting-table';
import { emailColumnDefs, jobsErrorReportColumnDefs } from './utilities/column-defs'
import TableWrapper from './shared/table-wrapper';
import { useTableStore } from './utilities/table-state';
import { GoogleMap, useJsApiLoader, Marker, Autocomplete } from '@react-google-maps/api';
import Loader from './shared/Loader'
import Error from './shared/Error'
import _ from 'lodash';
import { fmsData, attributeLabels } from './utilities/fms-data';


// Icons
import {
  AddCard,
  AdsClick,
  CalendarMonth,
  ContactMail,
  CurrencyExchange,
  Dvr,
  Feedback,
  Grading,
  HealthAndSafety,
  KeyboardDoubleArrowUp,
  LiveHelp,
  Map,
  MarkEmailUnread,
  Monitor,
  MonitorHeart,
  Payment,
  Percent,
  Person,
  Policy,
  RunningWithErrors,
  Sell,
  Summarize,
  ViewList,
  ViewModule,
  ViewSidebar,
} from "@mui/icons-material";
import AdminToolsSection from "./admin-tools/AdminToolsSection";
import { useAdminToolsStore } from "./admin-tools/useAdminToolsStore";

require('datejs');

function AttachRateReport() {
  const client = useApolloClient();

  const today = (() => {
    const date = new Date();
    date.setHours(0, 0, 0, 0);
    return date;
  })()
  const [loading, setLoading] = useState(false);
  const [reportDate, setReportDate] = useState(today);
  const [error, setError] = useState(null);

  const runAttachRateReport = async (e) => {
    if(!reportDate) {
      setError("You must choose a valid date.");
      return;
    }
    setError(null);

    try {
      e.preventDefault();
      setLoading(true);

      const savedResponse = await AdminDataService.attachRateReport(reportDate.toISOString().split('T')[0]);
      FileSaver.saveAs(savedResponse.data.url);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="admin-reports">
      <DatePicker
        label="Date"
        value={reportDate}
        views={['year', 'month', 'day']}
        onChange={(newDate) => setReportDate(newDate)}
        slotProps={{
          textField: {
            error,
            helperText: error
          }
        }}
        disabled={loading}
        disableFuture={true}
      />
      <Button sx={{ marginLeft: '1rem' }} variant="contained" onClick={runAttachRateReport} disabled={loading}>
        {loading ? (
          <span className="busy-indicator">
            <CircularProgress size="13px" sx={{color:"white", marginRight: "10px"}}/>{' '}
          </span>
        ) : (
          <>Download Report</>
        )}
      </Button>
    </div>
  );
}

async function invitationReport(e) {
  e.preventDefault();

  var response = await AdminDataService.invitationReport();
  const csvData = new Blob([response.data.output], { type: 'text/csv;charset=utf-8;' });
  var date = new Date().toISOString().split('T')[0];
  FileSaver.saveAs(csvData, `Invitation report (${date}).csv`);
  return false;
}

async function billingStatusReport(e) {
  e.preventDefault();

  var response = await AdminDataService.billingStatusReport();
  const csvData = new Blob([response.data.output], { type: 'text/csv;charset=utf-8;' });
  var date = new Date().toISOString().split('T')[0];
  FileSaver.saveAs(csvData, `Billing Status Report (${date}).csv`);
  return false;
}

async function userFeedbackReport(e) {
  e.preventDefault();

  var response = await AdminDataService.userFeedbackReport();
  const csvData = new Blob([response.data.output], { type: 'text/csv;charset=utf-8;' });
  var date = new Date().toISOString().split('T')[0];
  FileSaver.saveAs(csvData, `User Feedback Report (${date}).csv`);
  return false;
}

async function privatePolicyReport(e) {
  e.preventDefault();

  var response = await AdminDataService.privatePolicyReport();
  const csvData = new Blob([response.data.output], { type: 'text/csv;charset=utf-8;' });
  var date = new Date().toISOString().split('T')[0];
  FileSaver.saveAs(csvData, `Private Policy Report (${date}).csv`);
  return false;
}

async function bonusLocationsReport(e) {
  e.preventDefault();

  var response = await AdminDataService.bonusLocationsReport();
  const csvData = new Blob([response.data.output], { type: 'text/csv;charset=utf-8;' });
  var date = new Date().toISOString().split('T')[0];
  FileSaver.saveAs(csvData, `Bonus-Eligible Locations Report (${date}).csv`);
  return false;
}

async function coverageReport(e) {
  e.preventDefault();

  const response = await AdminDataService.coverageReport();
  const csvData = new Blob([response.data.output], { type: 'text/csv;charset=utf-8;' });
  const date = new Date().toISOString().split('T')[0];
  FileSaver.saveAs(csvData, `Coverage Report (${date}).csv`);
  return;
}

async function upcomingJobsReport(e) {
  e.preventDefault();

  const response = await AdminDataService.upcomingJobsReport();
  const csvData = new Blob([response.data.output], { type: 'text/csv;charset=utf-8;' });
  const date = new Date().toISOString().split('T')[0];
  FileSaver.saveAs(csvData, `Upcoming Jobs Report (${date}).csv`);
}

const adminTools = {
  billing: {
    label: "Billing",
    path: "/admin/tools/billing",
    id: "billing",
    icon: <CurrencyExchange />,
  },
  billingReport: {
    label: "Billing Report",
    path: "/admin/tools/billing_report",
    id: "billingReport",
    icon: <Payment />,
  },
  billingStatusReport: {
    label: "Billing Status Report",
    onClick: billingStatusReport,
    downloadable: true,
    id: "billingStatusReport",
    icon: <Sell />,
  },
  errorCenter: {
    label: "Jobs Error Center",
    path: "/admin/tools/error_center",
    id: "errorCenter",
    icon: <RunningWithErrors />,
  },
  jobHealth: {
    label: "Job Health",
    path: "/admin/tools/job_health",
    id: "jobHealth",
    icon: <MonitorHeart />,
  },
  upcomingJobsReport: {
    label: "Upcoming Jobs Report",
    onClick: upcomingJobsReport,
    downloadable: true,
    id: "upcomingJobsReport",
    icon: <CalendarMonth />,
  },
  matchClaims: {
    label: "Match Claims",
    path: "/admin/tools/match_claims",
    id: "matchClaims",
    icon: <LiveHelp />,
  },
  generalUnits: {
    label: "General Unit Record",
    path: "/admin/tools/general_units",
    id: "generalUnits",
    icon: <Dvr />
  },
  bonusEligibleLocationsReport: {
    label: "Bonus-Eligible Locations Report",
    id: "bonusEligibleLocationsReport",
    onClick: bonusLocationsReport,
    downloadable: true,
    icon: <AddCard />
  },
  locationMap: {
    label: "Location Map",
    path: "/admin/tools/map",
    id: "locationMap",
    icon: <Map />
  },
  reports: {
    label: "Reports",
    path: "/admin/tools/reports",
    id: "reports",
    icon: <Summarize />
  },
  attachRateReport: {
    label: "Attach Rate Report",
    path: "/admin/tools/attach_rate_report",
    id: "attachRateReport",
    icon: <Percent />,
  },
  coverageReport: {
    label: "Coverage Report",
    onClick: coverageReport,
    downloadable: true,
    id: "coverageReport",
    icon: <HealthAndSafety />,
  },
  impactReport: {
    label: "Impact Report",
    path: "/admin/tools/impact",
    id: "impactReport",
    icon: <AdsClick />,
  },
  invitationReport: {
    label: "Invitation Report",
    onClick: invitationReport,
    downloadable: true,
    id: "invitationReport",
    icon: <ContactMail />,
  },
  planUpgrades: {
    label: "Protection Plan Upgrades",
    path: "/admin/tools/plan_upgrades",
    id: "planUpgrades",
    icon: <KeyboardDoubleArrowUp />
  },
  complianceCenter: {
    label: "Private Policy Compliance Center",
    path: "/admin/tools/compliance_center",
    id: "complianceCenter",
    icon: <Grading />,
  },
  privatePolicyReport: {
    label: "Private Policy Report",
    onClick: privatePolicyReport,
    downloadable: true,
    id: "privatePolicyReport",
    icon: <Policy />,
  },
  userFeedbackReport: {
    label: "User Feedback Report",
    onClick: userFeedbackReport,
    downloadable: true,
    id: "userFeedbackReport",
    icon: <Feedback />,
  },
  userInfo: {
    label: "User Info",
    path: "/admin/tools/user_info",
    id: "userInfo",
    icon: <Person />,
  },
  upcomingEmails: {
    label: "Upcoming Emails",
    path: "/admin/tools/emails",
    id: "upcomingEmails",
    icon: <MarkEmailUnread />
  },
}


function AdminTools() {

  const { favorites, mode, toggleMode } = useAdminToolsStore();

  useEffect(() => {
    document.body.classList.add("dashboard");
    return () => {
      document.body.classList.remove("dashboard");
    };
  }, []);

  return (
    <>
      <TitleHeader title="Admin Tools"/>
      <Stack direction="row" justifyContent="flex-end">
        <ToggleButtonGroup exclusive value={mode} onChange={(_, newValue) => toggleMode(newValue)}>
          <ToggleButton value="list" sx={{ bgcolor: 'white' }}>
            <ViewList />
          </ToggleButton>
          <ToggleButton value="split" sx={{ bgcolor: 'white' }}>
            <ViewSidebar sx={{ transform: 'rotate(-90deg)' }} />
          </ToggleButton>
          <ToggleButton value="grid" sx={{ bgcolor: 'white' }}>
            <ViewModule />
          </ToggleButton>
        </ToggleButtonGroup>
      </Stack>
      {favorites.length > 0 && (
        <AdminToolsSection
        label="Favorites"
        tools={favorites.map((id) => adminTools[id])}
        />
      )}
      <AdminToolsSection
        label="Billing"
        tools={[adminTools.billing, adminTools.billingReport, adminTools.billingStatusReport]}
      />
      <AdminToolsSection
        label="Jobs"
        tools={[
          adminTools.errorCenter,
          adminTools.jobHealth,
          adminTools.upcomingJobsReport,
        ]}
      />
      <AdminToolsSection
        label="Claims"
        tools={[
          adminTools.claimsVisualizations,
          adminTools.matchClaims,
        ]}
      />
      <AdminToolsSection
        label="Facilities & Units"
        tools={[
          adminTools.generalUnits,
          adminTools.bonusEligibleLocationsReport,
          adminTools.locationMap,
          adminTools.planUpgrades,
          adminTools.complianceCenter
        ]}
      />
      <AdminToolsSection
        label="Reports"
        tools={[
          adminTools.reports,
          adminTools.attachRateReport,
          adminTools.coverageReport,
          adminTools.impactReport,
          adminTools.invitationReport,
          adminTools.privatePolicyReport,
          adminTools.userFeedbackReport,
        ]}
      />
      <AdminToolsSection
        label="Misc"
        tools={[
          adminTools.upcomingEmails,
          adminTools.userInfo
        ]}
      />
    </>
  );
}

function AdminReports() {
  const locations = useQuery(getAllLocations);
  const [locationId, setLocationId] = React.useState('');
  const [report, setReport] = React.useState('privatePolicyReport');

  const [busy, setBusy] = useState(false);
  
  if (locations.loading) return <Loader />;
  if (locations.error) return <Error />;


  const run = async function() {
    if(locationId === '') return;

    setBusy(true);
    var response = await AdminDataService.report(report, locationId);

    if (report === 'eligibleUnitsReport') {
      setBusy(false);
      const csvData = new Blob([response.data.output], { type: 'text/csv;charset=utf-8;' });
      FileSaver.saveAs(csvData, 'eligibleUnitsReport.csv');
    } else {
      var jobId = response.data.jobId;
      
      var jobResponse;
  
      while(true) {
        jobResponse = await JobsDataService.status(jobId, 'csv');
        if(jobResponse.data.status !== 'pending') {
          break;
        }
        await new Promise(r => setTimeout(r, 10000));
      }

      setBusy(false);

      const csvData = new Blob([jobResponse.data.output], { type: 'text/csv;charset=utf-8;' });
      FileSaver.saveAs(csvData, 'report.csv');
    }
    
  };

  return (
    <div className="admin-reports">
      <div>
        <Select
          onChange={e => setLocationId(e.target.value)}
          displayEmpty
          defaultValue=""
        >
          <MenuItem disabled value=""><em>Select a location</em></MenuItem>
          { locations.data.getAllLocations.map(location => {
            return (
              <MenuItem key={location.id} value={location.id}>{location.qualifiedName}</MenuItem>
            );
          })}
        </Select>
      </div>
      <div>
        <Select
          onChange={e => setReport(e.target.value)}
          value={report}
        >
          <MenuItem value="privatePolicyReport">Private policy</MenuItem>
          <MenuItem value="eligibleUnitsReport">Eligible units</MenuItem>
        </Select>
        
      </div>
      <div>
        <Button variant="contained" onClick={e => run()}>
          {busy && 
          <span className="busy-indicator">
            <CircularProgress size="13px" sx={{color:"white", marginRight: "10px"}}/>{' '}
          </span>
        }
          Run report
        </Button>
      </div>
    </div>
  );
}

function AdminBillingReport() {
  const llcs  = useQuery(getAllLlcs);
  const [llcId, setLlcId] = React.useState('');
  const [month, setMonth] = React.useState(null);
  
  const [busy, setBusy] = useState(false);
  
  if (llcs.loading) return <Loader />;
  if (llcs.error) return <Error />;

  const run = async function() {
    if(llcId === '' || !month) return;

    try {
      setBusy(true);
      const response = await AdminDataService.billing(llcId, month.toISOString().split('T')[0]);

      FileSaver.saveAs(response.data.url);
    } catch (err) {
      console.error('error getting the billing report: ' + err.message);
    } finally {
      setBusy(false);
    }
  };

  return (
    <Paper className="admin-billing">
      <div>
        <div className="element">
          <Select
            onChange={e => setLlcId(e.target.value)}
            displayEmpty
            defaultValue=""
            value={llcId}
          >
            <MenuItem disabled value=""><em>Select an LLC</em></MenuItem>
            <MenuItem value="all">All</MenuItem>
            { llcs.data.getAllLlcs.map(llc => {
              return (
                <MenuItem key={llc.id} value={llc.id}>{llc.name}</MenuItem>
              );
            })}
          </Select>
        </div>
        <div className="element">
          <DatePicker
            label="Month"
            value={month}
            views={['year', 'month']}
            onChange={(newDate) => setMonth(newDate)}
          />
        </div>
      </div>

      <div>
        <Button variant="contained" onClick={e => run()}>
          {busy && 
          <span className="busy-indicator">
            <CircularProgress size="13px" sx={{color:"white", marginRight: "10px"}}/>{' '}
          </span>
          }
          Get billing data
        </Button>
      </div>
    </Paper>
  );
}

function OtpEnroll() {
  const client = useApolloClient();
  const user = useQuery(me);
  
  const [token, setToken] = React.useState('');
  const [secret, setSecret] = React.useState();
  const [error, setError] = React.useState();
  const [enrolled, setEnrolled] = React.useState(false);

  async function enroll() {
    var response = await AdminDataService.otpEnroll(secret, token);
    if(response.data.error) {
      setError(response.data.error);
    } else {
      setEnrolled(true);
    }
  };

  async function dismiss() {
    await client.refetchQueries({
      include: [me],
    });
  }

  function keyDown(event) {
    if(event.key === "Enter") {
      enroll();
    }
  }

  if (user.loading) return <Loader />;
  if (user.error) return <Error />;

  if(!user.data.me.otpEnrollmentPossible) {
    return (
      <div>You do not have permission to access this functionality.</div>
    );
  }

  if(!secret) {
    // The alphabet is base 32, so each character represents 5 bits.
    // 32 characters * 5 bits = 160 bits
    const nanoid = customAlphabet('ABCDEFGHIJKLMNOPQRSTUVWXYZ234567', 32);
    setSecret(nanoid());
  }

  var issuer = "SafeLease";
  if(process.env.NODE_ENV != 'production') {
    issuer += `:${process.env.NODE_ENV}`;
  }
  
  var uri = `otpauth://totp/${issuer}:${user.data.me.email}` + '?' +  new URLSearchParams({secret, issuer});

  return (
    <div className="otp-enroll">
      <h2>One-time Password Enrollment Required</h2>
      <div className="instructions">
        <p>You have been selected for the highest level of access in the app, granting access to sensitive customer data.</p>
        <p>Take this access very seriously.  It is your job to prevent any possibility of your access being misused. Read the text on these screens carefully and ask questions if you don't understand.</p>

        <h4>How does this work?</h4>
        <p>This access will use one-time password authentication, or OTP.  OTP serves as a second line of defense after your password to virtually eliminate the possibility of unauthorized access via a stolen or cracked password.</p>
        <p>Your phone will serve as the proof of your identity to our app. When you need to access sensitive data, you will need to enter a six-digit OTP token that your phone provides.</p>
        <p>The token on your device changes every 30 seconds.  Only the current token will work to authenticate.  Authentication lasts for 24 hours.</p>

        <h4>What are the rules?</h4>
        <ul>
          <li>Never share your password, of course.  But you can store it in a password manager.</li>
          <li>Never share your current OTP code with anyone.  The only viable attack on this system is for an attacker to convince you to tell them the code over the phone in real time.</li>
          <li>Practice good phone security with a lock screen.</li>
          <li>If your phone is lost or stolen, notify development immediately to have your access blocked.</li>
          <li>If you need to enroll a new device, notify development to have enrollment re-enabled.</li>
        </ul>

        <h4>What do I do?</h4>
        <p>First, download the <b>Google Authenticator</b> app onto your smartphone.  It can be found in both the Android and Apple app stores and installed for free. </p>
        <p>Next, scan the following QR code with Google Authenticator:</p>

      </div>
      <div className="code">
        <QRCode value={uri} />
      </div>
      <div className="instructions">
        And now enter the six-digit code your phone shows.
      </div>
      
      {!enrolled && 
       <>
       <div className="token">
         <TextField label="Token" value={token} onChange={e => setToken(e.target.value)} error={!!error} helperText={error} sx={muiTextField} onKeyDown={keyDown}   />
       </div>
       <Button variant="contained" onClick={enroll}>Enroll</Button>
       </>
      }

      {enrolled &&
       <div className="instructions">
         <p>You are now enrolled.  The app and your device now share a secret that never has to leave the app or your device again.  You can now access sensitive data for the next 24 hours.</p>
         <Button variant="contained" onClick={dismiss}>Proceed</Button>
       </div>
      }
    </div>
  );
}

function OtpValidate() {
  const [token, setToken] = React.useState('');
  const [error, setError] = React.useState();
  
  async function validate() {
    var response = await AdminDataService.otpValidate(token);
    if(response.data.error) {
      setError(response.data.error);
    } else {
      localStorage.setItem('otpToken', response.data.otpToken);
      location.reload();
    }
  };
  
  function keyDown(event) {
    if(event.key === "Enter") {
      validate();
    }
  }

  return (
    <div className="otp-validate">
      <h3>One-time Password Verification Required</h3>
      <div className="instructions">
        This function requires one-time password validation.  Open the Google Authenticator app on your phone and enter the six-digit code it provides for SafeLease.
      </div>
      <div>
        <TextField label="Token" value={token} onChange={e => setToken(e.target.value)} error={error} helperText={error}  sx={muiTextField} onKeyDown={keyDown} />
      </div>
      <div>
        <Button variant="contained" onClick={validate}>Validate</Button>
      </div>
    </div>
  );
}

function OtpRequired({children}) {
  const result = useQuery(me);
  
  useEffect(async () => {
    try {
      await AdminDataService.otpPing();
    } catch (e) {
      // Already handled in http-common.js
    }
  }, []);

  if (result.loading) return <Loader />;
  if (result.error) return <Error />;
  
  var user = result.data.me;

  if(!user.otpEnrolled) {
    if(!user.otpEnrollmentPossible) {
      return (
        <div className="instructions">
          This function requires one-time password validation.  You are not eligible.  If this is an issue, contact development to get access.
        </div>
      );
    }


    return  <OtpEnroll />;
  }

  if(!localStorage.getItem('otpToken')) {
    return <OtpValidate />
  }

  return children;
}


function CopyButton(props) {
  const [copied, setCopied] = React.useState(false);
  
  return (
    <CopyToClipboard text={props.text} onCopy={() => setCopied(true)}>
      <IconButton>
        {copied ? <CheckIcon /> : <ContentCopyIcon />}
      </IconButton>
    </CopyToClipboard>
  );
}


function DisplayAttribute(props) {
  const { attribute, value } = props;

  if(['password', 'directPassword'].includes(attribute)) return '********';

  if(['url', 'directUrl'].includes(attribute)) {
    return (
      <>
        <a target="_blank" href={value}>{value}</a>
      </>
    )
  }

  return props.value;
}

function Credentials(props) {
  const match = useRouteMatch();

  const [message, setMessage] = React.useState('');
  const [credentials, setCredentials] = React.useState();
  const [errors, setErrors] = React.useState();

  async function view() {
    try {
      var response = await AdminDataService.getCredentials({
        locationId: match.params.locationId,
        message: message,
      });
    } catch (e) {
      // It must have been more than an hour after the page was loaded, so reload for re-validation.
      if(e.message.includes('401')) {
        window.location.reload();
      }
      if(e.message.includes('400')) {
        setErrors(e.response.data.errors);
      }
    }

    if(!response) return;

    if(response.data.errors) {
      setErrors(response.data.errors);
    }


    setCredentials(response.data.credentials);
  }

  function keyDown(event) {
    if(event.key === "Enter") {
      view();
    }
  }

  return (
    <OtpRequired>
      {!credentials && 
      <div className="view-credentials">
        <h3>View credentials</h3>
        <div className="instructions">
          You have chosen to view credentials for a location.  This is a highly sensitive action, so we keep an audit log of what was accessed when.  Please provide a log message.
          This message should serve to remind you of why you needed access to these credentials at a future date.  If you are investigating a ticket, provide the ticket number.
        </div>

        <div>
          <TextField label="Message" value={message} onChange={e => setMessage(e.target.value)} error={!!errors?.message} helperText={errors?.message?.msg} sx={muiTextField} onKeyDown={keyDown}/>
        </div>
        <div>
          <Button variant="contained" onClick={view}>View</Button>
        </div>
      </div>
      }

      {credentials &&
      <div className="view-credentials">
        <h3>Client Credentials</h3>

        <div className="instructions">
          The requested credentials are below.  Warning: saving these passwords outside this system is a violation of SafeLease policy.  They should only be used to log in and the credentials should not be saved in any browser.
        </div>
        
        <table>
          <tbody>
            <tr>
              <td>FMS</td>
              <td>
                {credentials.fms}
              </td>
            </tr>
            
            <tr>
              <td>FMS Location ID</td>
              <td>
                {credentials.serviceId}
              </td>
            </tr>
            
            {Object.entries(fmsData[credentials.type].sections).map(([label, attributes]) => {
              return attributes.map(attribute => {
                if(!credentials[attribute]) return null;
                return (
                  <tr>
                    <td>{attributeLabels[attribute]}</td>
                    <td>
                      <DisplayAttribute attribute={attribute} value={credentials[attribute]} />
                      {['username', 'password', 'apiUsername', 'apiPassword'].includes(attribute) && <CopyButton text={credentials[attribute]} />}
                    </td>
                  </tr>
                );
              });
            })}
          </tbody>
        </table>
      </div>
      }
    </OtpRequired>
  );
}


function BillingCredentials(props) {
  const match = useRouteMatch();

  const [message, setMessage] = React.useState('');
  const [credentials, setCredentials] = React.useState();
  const [errors, setErrors] = React.useState();

  async function view() {
    var response = await AdminDataService.getBillingCredentials({
      llcId: match.params.llcId,
      message: message,
    });

    if(response.data.errors) {
      setErrors(response.data.errors);
    }


    setCredentials(response.data.credentials);
  }

  function keyDown(event) {
    if(event.key === "Enter") {
      view();
    }
  }

  return (
    <OtpRequired>
      {!credentials && 
      <div className="view-credentials">
        <h3>View billing credentials</h3>
        <div className="instructions">
          You have chosen to view billing credentials for an LLC.  This is a highly sensitive action, so we keep an audit log of what was accessed when.  Please provide a log message.
          This message should serve to remind you of why you needed access to these credentials at a future date.  If you are investigating a ticket, provide the ticket number.
        </div>

        <div>
          <TextField label="Message" value={message} onChange={e => setMessage(e.target.value)} error={!!errors?.message} helperText={errors?.message?.msg} sx={muiTextField} onKeyDown={keyDown}/>
        </div>
        <div>
          <Button variant="contained" onClick={view}>View</Button>
        </div>
      </div>
      }

      {credentials &&
      <div className="view-credentials">
        <h3>Billing Credentials</h3>

        <div className="instructions">
          The requested credentials are below.
        </div>
        
        <table>
          <tbody>
            <tr>
              <td>LLC Name</td>
              <td>{credentials.llcName}</td>
            </tr>
            <tr>
              <td>ACH Routing Number</td>
              <td>
                {credentials.achRoutingNumber}
              </td>
            </tr>
            <tr>
              <td>ACH Account Number</td>
              <td>{credentials.achAccountNumber}</td>
            </tr>
          </tbody>
        </table>
      </div>
      }
    </OtpRequired>
  );
}

function UpcomingEmails() {
  const emails = useQuery(getAllEmails, {
    fetchPolicy: 'cache-and-network', // we always want the most up to date information on this internal tool
  });

  const exportEmails = () => {
    const csvString = convertArrayToCSV(emails.data.getAllEmails);
    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
    FileSaver.saveAs(blob, `Upcoming Emails (${new Date().toISOString().split('T')[0]})`)
  };

  if (emails.loading) return <Loader />
  if (emails.error) return <Error />

  return (
    <>
      <TitleHeader title="Upcoming Emails" />
      <Button onClick={exportEmails} sx={muiRedButton({})}>
        Export
      </Button>
      <TableWrapper title="Upcoming Emails" columns={emailColumnDefs}>
        <SortingTable
          title="Upcoming Emails"
          queryResultData={emails.data?.getAllEmails}
          queryResult={emails}
          columns={emailColumnDefs}
        />
        </TableWrapper>
    </>
  )
}

function RetryJob(props) {
  const match = useRouteMatch();
  const jobId = match.params.jobId;
  const job = useQuery(getJob, {variables: {jobId}});
  
  if (job.loading) return <Loader />
  if (job.error) return <Error />

  if(job.data.getJob == null) {
    return "Job not found";
  }

  console.log(job.data);
  
  const retry = async function() {
    await AdminDataService.retryJob(jobId);

    await job.refetch();
    setInterval(job.refetch, 10000);
  };


  return (
    <div>
      <div>Job: {job.data.getJob.methodDescription}</div>
      <div>Status: {job.data.getJob.statusDescription}</div>
      <div>Retries: {job.data.getJob.failureCount}</div>
      {job.data.getJob.status == 2 && 
        <Button variant="contained" onClick={retry}>Retry</Button>
      }
    </div>
  );
}

function ImpactReport(props) {
  var yesterday = Date.today();
  yesterday.addDays(-1);

  const [reportDate, setReportDate] = React.useState(yesterday);

  const download = async function() {
    const response = await AdminDataService.impactReport(reportDate);
    const csvData = new Blob([response.data.output], { type: 'text/csv;charset=utf-8;' });
    FileSaver.saveAs(csvData, `Impact report (${reportDate.toString('yyyy-MM-dd')}).csv`);
  };

  return (
    <div>
      <TitleHeader title="Impact Report" />
      <div>
      <DatePicker
        label="Date"
        value={reportDate}
        views={['year', 'month', 'day']}
        onChange={(newDate) => setReportDate(newDate)}
        maxDate={yesterday}
      />
      </div>
      <div>
        <Button variant="contained" onClick={download}>Download</Button>
      </div>
    </div>
  );
}

function LocationsMap() {
  const [fms, setFms] = useState(ProspectFms.All);
  const [prospects, setProspects] = useState([]);
  const [page, setPage] = useState(1);
  const [map, setMap] = useState(null);
  const [autocomplete, setAutocomplete] = useState(null);
  
  const locations = useQuery(getAllLocations);
  const prospectsQuery = useQuery(getAllProspects, {
    variables: { page },
    onCompleted: (data) => {
      if (data.getAllProspects?.length) {
        setProspects(prospects.concat(data.getAllProspects));
        setPage(page + 1);
      }
    },
  });

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyAW8FSl4u68WQbW57iRTikBYR4FwAXh4zU",
    libraries: ['places'],
  });
    
  useEffect(() => {
    document.body.className = 'dashboard';
    return () => { document.body.className = ''; }
  }, []);

  const onLoad = React.useCallback(function callback(map) {
    setMap(map)
  }, []);
  
  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])
      
  if (locations.loading) return <Loader />
  if (locations.error) return <Error />
  if(!isLoaded) return <Loader />;

  
  const autocompleteOnLoad = function(autocomplete) {
    setAutocomplete(autocomplete);
  };

  const onPlaceChanged = function() {
    if (autocomplete !== null && !!autocomplete.getPlace().geometry?.location) {
      console.log(autocomplete.getPlace().geometry.location);
      map.setCenter(autocomplete.getPlace().geometry.location);
      map.setZoom(10);
    }
  }

  const containerStyle = {
    width: '100%',
    height: '80vh'
  };
  
  // Geographical center of the continental US
  const center = {
    lat: 39.83, 
    lng: -98.58,
  };

  return (
    <div>
      <div className='tw-flex tw-flex-col'>
        <span className='tw-text-xs tw-font-bold tw-mr-2'>Prospect FMS:</span>
        <SafeleaseDropdown defaultValue={ProspectFms.All}
            onChange={(event) => {
              setFms(event.target.value)
            }}
            menuOptionList={Object.keys(ProspectFms)} />
      </div>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={5}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
        <Autocomplete onLoad={autocompleteOnLoad} onPlaceChanged={onPlaceChanged}>
          <>
          <input
              type="text"
              placeholder="Enter an address"
              style={{
                boxSizing: `border-box`,
                border: `1px solid transparent`,
                width: `240px`,
                height: `32px`,
                padding: `0 12px`,
                borderRadius: `3px`,
                boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                fontSize: `14px`,
                outline: `none`,
                textOverflow: `ellipses`,
                position: "absolute",
                right: "0%",
                marginRight: "75px",
                marginTop: "10px",
              }}
          />

          {locations.data.getAllLocations.filter(location => location.lat && location.lng).map(location => {
            return (
              <Marker key={location.id} position={{lat: location.lat, lng: location.lng}} 
                  title={location.fullAddress}/>
            );
          })}
          {fms != ProspectFms.None &&
            <>
              {prospects.filter(
                  prospect => prospect.lat && prospect.lng && 
                      (fms == ProspectFms.All || fms == prospect.fms)
                  ).map((prospect, i)=> {
                return (
                  <Marker position={{lat: prospect.lat, lng: prospect.lng}} 
                      onClick={(e) => window.open(prospect.salesforceUrl, '_blank')}
                      key={i}
                      icon='https://maps.google.com/mapfiles/ms/icons/blue-dot.png'
                      title={`${prospect.name} - ${prospect.fms} - ${prospect.address}, ${prospect.city}, ${prospect.state} - ${prospect.website}`} />
                );
              })}
            </>
          }
      </>
        </Autocomplete>
      </GoogleMap>
    </div>
  );
}

export {
  AdminTools,
  AdminReports,
  AdminBillingReport,
  AttachRateReport,
  OtpEnroll,
  Credentials,
  BillingCredentials,
  UpcomingEmails,
  RetryJob,
  ImpactReport,
  OtpRequired,
  LocationsMap,
}
