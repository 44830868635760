// UI - libs
import { Stack, Typography } from "@mui/material";
import { PrivateReview_Reputation as PrivateReview } from "@safelease/service-utilities";

// UI - internal
import { FacilityFeedbackCard } from "./FacilityView/FacilityViewTabs/FeedbackOverview/FacilityFeedbackCard";

interface NonPublicTenantReviewsProps {
  reviews: Array<PrivateReview>;
}

/**
 * Return either:
 *  (1) a list of reviews that need to be responded to
 *  (2) a message indicating that there are no reviews to respond to
 */
export function NonPublicTenantReviews({ reviews = [] }: NonPublicTenantReviewsProps) {
  if (!reviews || reviews.length === 0) {
    return (
      <Typography variant="body1" color="grey.A100">
        No reviews to respond to. You're all caught up!
      </Typography>
    );
  }

  return (
    <Stack direction="column" spacing={1}>
      {reviews.map((review) => (
        <FacilityFeedbackCard feedback={review} key={review.id} />
      ))}
    </Stack>
  );
}
